import React, { Component } from 'react';
import axios from "axios";
import { Redirect } from 'react-router';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Cookies from 'universal-cookie';
import Visitor from './visitor';
import Headers from './header';
import Footer from './footer';
import { v4 as uuidv4 } from 'uuid'

const config = require('../config.json');
const cookies = new Cookies();

class registerSave extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      redirect: false,
      directTo: "/inductionVideo",
      id: this.props.location.state.id,
      email: this.props.location.state.email,
      firstname: this.props.location.state.firstname,
      lastname: this.props.location.state.lastname,
      company: this.props.location.state.company,
      mobile: this.props.location.state.mobile,
      image: this.props.location.state.image,
      newVisitor: {
        "id": "",
        "firstname": "",
        "lastname": "",
        "company": "",
        "email": "",
        "laptop": "",
        "laptopserial": "",
        "mobile": "",
        "vehicle": "",
        "image": ""
      },
      currentMonth: new Date().getMonth() + 1,
      currentYear: new Date().getUTCFullYear(),
      registerVisitor: false
    };
    this.handleRegister = this.handleRegister.bind(this);
    this.handleNo = this.handleNo.bind(this);
  }

  handleRegister() {

    this.AddVisitsVisitor();
    
    this.setState({ directTo: "/register" });
    this.setState({ redirect: true });
  }

  handleNo() {

    this.AddVisitsVisitor();

    this.setState({ directTo: "/inductionVideo" });
    this.setState({ redirect: true });
  }

  // handleSaveVisitor() {
  //   const guid = uuidv4();
  //   this.AddVisitsVisitor(
  //     this.state.id,
  //     this.state.firstname,
  //     this.state.lastname,
  //     guid,
  //     this.state.email,
  //     this.state.company,
  //     this.state.mobile,
  //     this.state.image);

  //   // this.setState({ directTo: "/additionalVisitor" });
  //   this.setState({ directTo: "/inductionVideo" });
  //   this.setState({ redirect: true });
  // }

  AddVisitsVisitor = async () => {
    const curTime = new Date().toLocaleString();

    let currentTimeInMilliseconds = " ";
    currentTimeInMilliseconds = Date.now().toString(); // unix timestamp in milliseconds 
    const guid = uuidv4();

    try {

      const params = {
        "newid": this.state.currentYear.toString() + this.state.currentMonth.toString(),
        "timestamps": currentTimeInMilliseconds,
        "id": guid,
        "visitkey": cookies.get('DVvisitkey'),
        "visitorid": this.state.id,
        "visitorname": this.state.firstname,
        "visitorlastname": this.state.lastname,
        "visitormobile": this.state.mobile,
        "visitoremail": this.state.email,
        "visitorcompany": this.state.company,
        "visitortype": cookies.get("DVvisitorType"),
        "country": cookies.get("DVCountry"),
        "building": cookies.get("DVBuilding"),
        "visitdate": cookies.get("DVvisitdate"),
        "hostname": cookies.get("DVHostName"),
        "visitreason": cookies.get("DVvisitreason"),
        "visitorimage": this.state.image,
        "visittime": cookies.get("DVvisitDuration"),
        username: localStorage.getItem('email'),
        usid: localStorage.getItem('usersession')
      };

      console.log(params);

      await axios.post(`${config.api.invokeUrl}/visitsvisitor/{id}`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      this.setState({ directTo: "/inductionVideo" });
      this.setState({ redirect: true });

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.directTo,
        state: {
          id: this.state.id,
          email: this.state.email,
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          company: this.state.company,
          mobile: this.state.mobile,
          mode: "Email",
          image: this.state.image,
          registervisitor: this.state.registerVisitor
        }
      }} />;
    }
    return (

      <div className="page-container">
        <div className="content-wrap">
          <Headers></Headers>
          <p></p>

          <div className="text-center">
            <h5 className="subTitleText" style={{ padding: '0px 10px 0px 10px', margin: '30px 0px 30px 0px' }} >Would you like to register your information for easier access with your ID number on your next visit to Pernod Ricard?</h5>
            <br></br>

            <Container>
              <Row>
                <Col className="text-right"></Col>
                <Col xs={6}>
                  <Button onClick={this.handleRegister} variant="danger">Yes</Button>{' '}
                  <Button onClick={this.handleNo} variant="secondary">No</Button>{' '}
                </Col>
                <Col></Col>
              </Row>
            </Container>

            <Footer></Footer>
          </div>
        </div>
      </div>
    )
  }
}

export default registerSave;