import React, { Component } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import ADMINICO from "../admin.png";
import DURATION from "../clock-or-timed.png";
import SEARCHICO from "../search.png";
import HELPICO from "../help-circle.png";
import profilePic from "../profilePic.png";
import INFO from "../info-circle red.png";
import searchWhite from "../searchWhite.png";
import deliveryIcon from "../delivery.png";
import LOADING from "../tenor.gif";
import Cookies from "universal-cookie";
import Modal from "react-bootstrap/Modal";
import Contact from "./contact";
import axios from "axios";
import { Redirect } from "react-router";
import { v4 as uuidv4 } from "uuid";
import {
  FormWithConstraints,
  FieldFeedbacks,
  Async,
  FieldFeedback,
} from "react-form-with-constraints";
import Table from "react-bootstrap/Table";
import Headers from './header';
import Footer from './footer';
import { search } from '../utils'

const config = require("../config.json");

const cookies = new Cookies();

class deliveryDetailsPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleInfo = this.handleInfo.bind(this);
    this.searchContactsClick = this.searchContactsClick.bind(this);

    this.state = {
      show: false,
      showInfo: false,
      showContactError: false,
      
      loadingimg: "hidden",
      delivererName: "",
      courierCompany: cookies.get("courierCompany"),
      buildingName: cookies.get("DVBuilding"),
      contacts: [],
      contactsTest: [],
      directTo: "/dvHost",
      inputValue: "",
      redirect: false,
      receiverName: "",
      receiverMobile: "",
      receiverEmail: "",
      receiverCountry: localStorage.getItem('country'),
      receiverPhoto: "",
      deliveryKey: "",
      showReasonError: 'none',
      showLeavingError: 'none',
      showDurationError: 'none',
      showSearchError: 'none',
      showDelivererError: 'none',
      countryFlag: "",
      searchState: ''
    };

    this.focusTextInput = () => {
      // Focus the text input using the raw DOM API
      if (this.textInput) this.textInput.focus();
    };
  }

  componentDidMount = () => {
    // cookies.set("DVvisitVisitors", '', { path: '/', secure: true, sameSite: "lax" });
    // this.setState({ buildingName: cookies.get("DVBuilding") });
    
    // const country = this.removeUnderscore(localStorage.getItem('country'));
    // this.setState({ countryFlag: url + country });

    //console.log(this.state.receiverCountry);

  };

  removeUnderscore(stringfromdb) {
    var string = stringfromdb;
    string = string.replace(/_/g, " ");
    return string;
  }

  handleClose() {
    this.setState({ show: false });
    this.setState({ showInfo: false });
    this.setState({ showContactError: false });
    
  }

  handleReject() {
    this.setState({ show: false });
    this.setState({ redirect: true });
  }

  handleInfo() {
    this.setState({ showInfo: true });
  }

  handleShow() {
    this.setState({ show: true });
    this.focusTextInput();
  }

  handleSelectContact = (name, mail, mobile, photo) => {

    this.refs.searchHost.value = '';

    if (mail === '') {
      this.setState({ show: false });
      this.setState({ showContactError: true });
    } else {
      
      this.setState({ receiverName: name });
      this.setState({ receiverMobile: mobile });
      this.setState({ receiverEmail: mail });
      this.setState({ receiverPhoto: photo });
      
      this.setState({ show: false });
      
      // const cookies = new Cookies();
      // cookies.set("receiverName", name, { path: '/', secure: true, sameSite: "lax" });
      // cookies.set("receiverMail", mail, { path: '/', secure: true, sameSite: "lax" });
      // cookies.set("receiverMobile", mobile, { path: '/', secure: true, sameSite: "lax" });
      // if (photo.length > 4096) {
      //   cookies.set("receiverPhoto", '', { path: '/', secure: true, sameSite: "lax" });
      // } else {
      //   cookies.set("receiverPhoto", photo, { path: '/', secure: true, sameSite: "lax" });
      // }
      // cookies.set("deliveryKey", guid, { path: '/', secure: true, sameSite: "lax" });
    }
  };

  onAddVisitorNameChange = (event) =>
    this.setState({
      newVisitor: { ...this.state.newVisitor, firstname: event.target.value },
    });

  handleError = err => {
    console.error(err)
  }

  handleCancel = e => {
    this.setState({ directTo: "/dvHost" });
    this.setState({ redirect: true });
  }

  updateDelivererValue(e) {
    this.setState({
      delivererName: e.target.value,
    });
  }

  updateInputValue(e) {
    this.setState({
      inputValue: e.target.value,
    });
  }

  searchContactsClick() {
    //alert("searchContactsClick");   
    this.setState({ searchState: "Searching..." });
    this.setState({ loadingimg: "none" }, function () {
      this.setState({ loadingimg: "block" });
      this.searchContacts();
    });
  }

  searchContacts = async () => {

    this.setState({ contacts: null });
    this.setState({ loading: true });
    let objResult = '';

    const params = {
      searchString: this.state.inputValue.toLowerCase()
    };

    try {

      const res = await axios.post(`${config.api.invokeUrl}/employeesearch/`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      //console.log(res.data[0]);

      if (res.data.length === 0) {
        this.setState({ searchState: "No search results found, please try again." });
      } else {
        this.setState({ searchState: "" });
        //convert JSON object into array
        //let result = Object.values(res.data[0]);
        //console.log(result);

        //this.setState({ contactsTest: res.data[0] });
        objResult = JSON.stringify(res.data);

        //console.log(objResult);
        this.setState({ contacts: JSON.parse(objResult) });

        //console.log(this.state.contacts);
      }
      this.setState({ loadingimg: "hidden" });

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  handleContinue = e => {
    e.preventDefault();
    let formValid = true;

    //this.form.validateFields();
    //alert(this.form.isValid());

    //Manually check if duration and type exist.

    if (this.state.delivererName === '') {
      this.setState({ showDelivererError: 'block' });
      formValid = false;
    } else {
      this.setState({ showDelivererError: 'none' });
    }
    if (this.state.receiverName === '') {
      this.setState({ showSearchError: 'block' });
      formValid = false;
    } else {
      this.setState({ showSearchError: 'none' });
    }

    if (formValid === true) {
      //Save to the DB
      this.handleAddDelivery(
        this.state.delivererName,
        this.state.courierCompany,
        this.state.receiverName,
        this.state.receiverEmail,
        this.state.receiverMobile,
        this.state.receiverCountry,
      );

      this.setState({ directTo: "/dvHost" });
      this.setState({ redirect: true });
      
    }
  };

  handleAddDelivery = async (deliverer, courier, name, mail, mobile, country) => {
    const curTime = new Date().toLocaleString();
    // cookies.set("DVvisitdate", curTime, { path: '/', secure: true, sameSite: "lax" });
    // cookies.set("DVvisitreason", visitType, { path: '/', secure: true, sameSite: "lax" });
    // cookies.set("DVvisitDuration", time, { path: '/', secure: true, sameSite: "lax" });

    const guid = uuidv4();
    // this.setState({ deliveryKey: guid });


    try {
      const params = {
        id: guid,
        deliverer: deliverer,
        couriercompany: courier,
        receivername: name,
        receiveremail: mail,
        receivermobile: mobile,
        country: country,
        deliverydate: curTime,
        
        username: localStorage.getItem('email'),
        usid: localStorage.getItem('usersession')
      };
      
      console.log(params);
      // return;

      await axios
      .post(`${config.api.invokeUrl}/delivery/{id}`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      })
      .then(data => {
        console.log('handleAddDelivery complete');
        // Notify employee

        this.sendEmailMessage(curTime);
        //todo: Enable this for sending SMS
        // this.sendSMSMessage();

      })
      .catch(err => {
        console.log(err);
        return null;
      });

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  };

  sendEmailMessage = async (curTime) => {
    
    const emailBody = "<p>Dear " + this.state.receiverName + ",</p><p>A parcel was delivered at <b>" + this.state.buildingName + "</b> Reception Area.</p><p>Courier Company: <b>" + this.state.courierCompany + "</b><br>By: <b>" + this.state.delivererName + "</b><br>Date and time: <b>" + curTime + "</b></p>     <p>Kind Regards,<br/>Digital Visitors Team</p>";

    const params = {
      "toaddress": this.state.receiverEmail,
      "subject": "Your parcel arrived",
      "body": emailBody
    };
    //console.log(params);

    try {
      const res = await axios.post(`${config.api.invokeUrl}/email/`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      if (res.status === "200") {
        // this.setState({ redirect: true });
      } else {
        console.log(`An error has occurred trying to send email`);
      }

    } catch (err) {
      console.log(`An error has occurred trying to send email: ${err}`);
    }
  }

  sendSMSMessage = async () => {
    const visitorsCount = this.state.visitors.length;
    const visitorsCurrent = this.state.visitors;
    const hostMobile = cookies.get('DVHostMobile');
    const hostName = cookies.get('DVHostName');
    const building = cookies.get('DVBuilding');

    // no need to send sms if there is no number
    if (hostMobile === '') {
      return;
    }

    let visitorParty = "";

    if (visitorsCount > 0) {
      if (visitorsCount === 1) {
        this.state.visitors.map((visitor, index) =>
          visitorParty = visitorParty + visitor.visitorname + ' ' + visitor.visitorlastname
        );
      }
      else if (visitorsCount > 1) {
        let lastVisitor = visitorsCurrent[visitorsCurrent.length - 1];
        let restOfVisitors = visitorsCurrent.pop();
        visitorsCurrent.map((visitor, index, visitorsCurrent) => {
          if (visitorsCurrent.length - 1 === index) {
            visitorParty = visitorParty + visitor.visitorname+' '+visitor.visitorlastname+' and '+lastVisitor.visitorname+' '+lastVisitor.visitorlastname
          } else {
            visitorParty = visitorParty + visitor.visitorname+' '+visitor.visitorlastname+', '
          }
        });
      }
    }
    
    if (visitorParty === '') {
      return;
    }

    const smsBody = "Dear " + hostName + ", Please be advised that " + visitorParty + " has arrived at " + building + " Reception Area.";

    const params = {
      "tomobile": hostMobile,
      "body": smsBody
    };

    // console.log(params);

    try {
      const res = await axios.post(`${config.api.invokeUrl}/sms/`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`,
          'Content-Type': 'application/json'
        }
      });

      if (res.status === "201") {
        // this.setState({ redirect: true });
      } else {
        console.log(`An error has occurred trying to send sms`);
      }

    } catch (err) {
      console.log(`An error has occurred trying to send sms: ${err}`);
    }

  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            // pathname: "/visitorwelcome",
            pathname: this.state.directTo,
            state: {
              receiverName: this.state.receiverName,
              receiverMobile: this.state.receiverMobile,
              receiverEmail: this.state.receiverEmail,
              receiverCountry: this.state.receiverCountry,
              receiverPhoto: cookies.get('DVreceiverPhoto'),
              photo: this.state.contacts.photo
            },
          }}
        />
      );
    }
    return (

      <div className="page-container">
        <div className="content-wrap">
          <Headers></Headers>
          <p></p>
          <div className="text-center">
            <span className="subTitleText">{this.state.courierCompany} - Delivery Details</span>


            {/* <span style={{ fontSize: "15px" }}><h3>Welcome to {this.state.buildingName}</h3>   <img src={this.state.countryFlag} style={{ height: "20px", width: "30px" }} alt="flag" /></span>

        <br />
        {" "}
        <h4>Who are you visiting?</h4> */}


            <FormWithConstraints
              ref={form => this.form = form}
              onSubmit={this.handleSubmit}
              noValidate>
              <div className="container">

                <div className="form-row justify-content-center">
                  <Table className="table-borderless" style={{ width: "310px" }}>
                    <tbody>
                    <tr>
                        <td>
                          <img
                            src={ADMINICO}
                            style={{
                              width: "20px",
                              padding: "0px 0px 10px 0px",
                              margin: "0px 5px 0px 0px",
                            }}
                          />
                        </td>
                        <td colSpan="3">
                          <input
                            name="delivererName"
                            id="delivererName"
                            type="text"
                            placeholder="Your name *"
                            className="form-control+matching-text-style"
                            style={{ width: "250px" }}
                            required
                            onChange={(e) => this.updateDelivererValue(e)}
                            value={this.state.delivererName}
                          />
                          <span style={{ margin: '0px 0px 10px 0px', display: this.state.showDelivererError, color: 'red' }}>Please enter your name.</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src={ADMINICO}
                            style={{
                              width: "20px",
                              padding: "0px 0px 10px 0px",
                              margin: "0px 5px 0px 0px",
                            }}
                          />
                        </td>
                        <td colSpan="3">
                          <input
                            name="search"
                            id="search"
                            type="text"
                            placeholder="Receiver of this parcel *"
                            className="form-control+matching-text-style"
                            onChange={() => { }}
                            style={{ width: "250px" }}
                            required
                            onClick={this.handleShow}
                            value={this.state.receiverName}
                          />
                          <span style={{ margin: '0px 0px 10px 0px', display: this.state.showSearchError, color: 'red' }}>Please select the receiver.</span>
                        </td>
                        <td>
                          <img
                            src={INFO}
                            onClick={this.handleInfo}
                            style={{
                              width: "30px",
                              padding: "0px 0px 0px 0px",
                              margin: "0px 0px 0px 0px",
                              cursor: "pointer"
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>



                </div>
              </div>

              <div className="form-row justify-content-center">
                    <Button onClick={this.handleContinue} variant="secondary" style={{ margin: '20px 3px 5px 0px', width: '110px', fontSize: "14px" }} disabled={this.state.btnsDisabled}>Continue</Button>{' '}
                    <Button onClick={this.handleCancel} variant="danger" style={{ margin: '20px 3px 5px 0px', width: '110px', fontSize: "14px" }}>Cancel</Button>{' '}
              </div>

              {/* <button
                type="submit"
                className="btn btn-secondary"
                style={{ width: "200px", margin: "0px 0px 90px 0px" }}
              >
                Next
              </button>{" "} */}


              <input
                name="visitType"
                id="visitType"
                type="text"
                onChange={() => { }}
                style={{ width: "0px", height: "1px" }}
                required
                value={this.state.visitType}
              />

            

            </FormWithConstraints>
            
            {/* <div textAlign="right">
              <Button onClick={this.handleLeaving} className="btn btn-warning" style={{ margin: '0px 0px 30px 0px', width: '200px', fontSize: "16px" }}>Visitor Checkout</Button>{' '}
            </div> */}

            <Modal
              show={this.state.show}
              onHide={this.handleClose}
              className="text-center"
              backdrop="static"
              keyboard={false}
            >

              <Modal.Body>
                <p>
                  <b>Search receiver name or surname</b>
                </p>
                <div className="form-group">
                  <input
                    name="searchHost"
                    id="searchHost"
                    type="text"
                    placeholder="Search"
                    className="form-control+matching-text-style"
                    style={{ width: "85%", margin: "0px 10px 0px 0px" }}
                    value={this.state.inputValue}
                    ref="searchHost"
                    onChange={(evt) => this.updateInputValue(evt)}
                    onClick={this.focusTextInput}
                    autoComplete="off"
                  />
                  <Button variant="danger" onClick={this.searchContactsClick} style={{ width: "40px", height: "30px", margin: "-10px 0px 0px 0px" }}>
                    <img
                      src={searchWhite}
                      onClick={this.searchContactsClick}
                      style={{
                        width: "20px",
                        padding: "0px 0px 0px 0px",
                        margin: "-10px 0px 0px 0px",
                        cursor: "pointer",
                      }} />
                  </Button>
                </div>
                <div className="form-group">
                  <span style={{ color: "red" }}>{this.state.searchState}</span>
                </div>
                <div>
                  <div className="tile is-4 is-parent  is-vertical" style={{ height: "300px", overflowY: "scroll" }}>
                    {this.state.contacts && Object.keys(this.state.contacts).length > 0 ? (
                      this.state.contacts.map((contact) => (
                        <Contact
                          handleSelectContact={this.handleSelectContact}
                          mail={contact.email}
                          name={contact.preferredname}
                          mobile={contact.mobile}
                          photo={contact.photo}
                          key={contact.email}
                          title={contact.title}
                        />
                      ))
                    ) : (
                      <div className="tile notification is-warning">
                        <p></p>
                      </div>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>

             <Modal
              show={this.state.showInfo}
              onHide={this.handleClose}
              className="text-center"
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  <span style={{ color: "red", fontWeight: "bold" }}>Unable to find the receiver of this parcel?</span>
                </p>
                <p>
                  <span>Please speak to the receptionist for assistance.</span>
                </p>
                <div className="form-group">

                </div>
                <div>

                </div>
              </Modal.Body>
              <Modal.Footer >
                <Button variant="danger" onClick={this.handleClose} block>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              show={this.state.showContactError}
              onHide={this.handleClose}
              className="text-center"
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  <span style={{ color: "red", fontWeight: "bold" }}>The selected receiver does not have an email address specified.</span>
                </p>
                <p>
                  <span>Please speak to the receptionist for assistance.</span>
                </p>
                <div className="form-group">

                </div>
                <div>

                </div>
              </Modal.Body>
              <Modal.Footer >
                <Button variant="danger" onClick={this.handleClose} block>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Footer></Footer>

          </div>
        </div>
      </div>
    );
  }
}

export default deliveryDetailsPage;


