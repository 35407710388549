import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Redirect } from 'react-router';
import spacer from "../spacer.png";

const config = require('../config.json');

class otpPref extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShowCapture = this.handleShowCapture.bind(this);

        const LoginId = this.props.location.state.id;
        const LoginMobile = this.props.location.state.mobile;
        const LoginEmail = this.props.location.state.email;
        const LoginCountry = this.props.location.state.country;
        // console.log(LoginId);
        // console.log(LoginMobile);
        // console.log(LoginEmail);
        // console.log('otpPref '+LoginCountry);

        this.state = {
            redirect: false,
            Id: this.props.location.state.id,
            Mobile: this.props.location.state.mobile,
            Email: this.props.location.state.email,
            Country: this.props.location.state.country,
            Region: this.props.location.state.region,
            Buildings: this.props.location.state.buildings,
            Mode: "Mobile"
        };
    }
    setGender(event) {
        // console.log(event.target.value);
        this.setState({ Mode: event.target.value });
    }

    handleShowCapture() {
        this.setState({ redirect: true });
    }

    render() {

        if (this.state.redirect) {
            return <Redirect push to={{
                pathname: "/otpFields",
                state: {
                    id: this.state.Id,
                    mobile: this.state.Mobile,
                    email: this.state.Email,
                    mode: this.state.Mode,
                    country: this.state.Country,
                    region: this.state.Region,
                    buildings: this.state.Buildings,
                    target: "/regionSelect"
                }
            }} />;
        }
        return (
            <div>
                <div className="text-center">
                    <h3 style={{ margin: '50px 0px 0px 0px' }}>Select the preferred OTP channel</h3>

                    <br />
                    <Container>
                        <Row>
                            <Col></Col>
                            <Col xs={6}>
                                <div onChange={this.setGender.bind(this)}>
                                    <input type="radio" value="Email" name="otppref" /> Email <span>  </span>
                                    <input type="radio" value="Mobile" name="otppref" defaultChecked /> Mobile
                                </div>
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row>
                            <Col></Col>
                            <Col xs={6}>
                                <p />
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row>
                            <Col></Col>
                            <Col xs={6}>
                                <Button variant="secondary" onClick={this.handleShowCapture} block>Send</Button>{' '}
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>

                    <br />
                </div>

                <div className="main-footer">
                    <div className="container">
                        <img src={spacer}></img>
                    </div>
                    <div className="page-containerRight"></div>
                </div>

            </div>
        );
    }
}

export default otpPref;