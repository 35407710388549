import React, { Component } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import ADMINICO from "../admin.png";
import DURATION from "../clock-or-timed.png";
import SEARCHICO from "../search.png";
import HELPICO from "../help-circle.png";
import profilePic from "../profilePic.png";
import INFO from "../info-circle red.png";
import searchWhite from "../searchWhite.png";
import deliveryIcon from "../delivery.png";
import LOADING from "../tenor.gif";
import Cookies from "universal-cookie";
import Modal from "react-bootstrap/Modal";
import Contact from "./contact";
import axios from "axios";
import { Redirect } from "react-router";
import { v4 as uuidv4 } from "uuid";
import {
  FormWithConstraints,
  FieldFeedbacks,
  Async,
  FieldFeedback,
} from "react-form-with-constraints";
import Table from "react-bootstrap/Table";
import Headers from './header';
import Footer from './footer';
import { search } from '../utils'

const config = require("../config.json");



const cookies = new Cookies();

class deliveryOptionsPage extends Component {
  constructor(props, context) {
    super(props, context);

    // this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    // this.handleInfo = this.handleInfo.bind(this);
    // this.handleHr = this.handleHr.bind(this);
    // this.handleVisitType = this.handleVisitType.bind(this);
    this.handleDelivery = this.handleDelivery.bind(this);
    this.searchContactsClick = this.searchContactsClick.bind(this);
    this.handleCollectionMyDelivery = this.handleCollectionMyDelivery.bind(this);

    this.state = {
      show: false,
      showInfo: false,
      showContactError: false,
      
      loadingimg: "hidden",
      buildingName: cookies.get("DVBuilding"),
      contacts: [],
      contactsTest: [],
      directTo: "/visitorwelcome",
      inputValue: "",
      redirect: false,
      hostName: "",
      hostMobile: "",
      hostEmail: "",
      hostCountry: localStorage.getItem('country'),
      hostPhoto: "",
      visitKey: "",
      visitDuration: "",
      hrs: "0",
      mins: "00",
      visitType: "",
      showReasonError: 'none',
      showLeavingError: 'none',
      showDurationError: 'none',
      showSearchError: 'none',
      countryFlag: "",
      searchState: ''
    };

    this.focusTextInput = () => {
      // Focus the text input using the raw DOM API
      if (this.textInput) this.textInput.focus();
    };

  }

  componentDidMount = () => {

  };

  removeUnderscore(stringfromdb) {
    var string = stringfromdb;
    string = string.replace(/_/g, " ");
    return string;
  }

  handleClose() {
    this.setState({ show: false });
    this.setState({ showInfo: false });
    this.setState({ showContactError: false });
    
  }

  handleCancel = e => {
    this.setState({ directTo: "/dvHost" });
    this.setState({ redirect: true });
  }
  
  handleDelivery = e => {
    this.setState({ directTo: "/delivery" });
    this.setState({ redirect: true });
  }

  handleCollectionMyDelivery = e => {
    this.setState({ directTo: "/delivery" });
    this.setState({ redirect: true });
  }


  handleSelectContact = (name, mail, mobile, photo) => {

    this.refs.searchHost.value = '';

    if (mail === '') {
      this.setState({ show: false });
      this.setState({ showContactError: true });
    } else {
      const guid = uuidv4();
      this.setState({ hostName: name });
      this.setState({ hostMobile: mobile });
      this.setState({ hostEmail: mail });
      this.setState({ hostPhoto: photo });
      this.setState({ visitKey: guid });
      this.setState({ show: false });
      //Save the contact and meeting GUID in cookies

      //console.log(name+' '+mail+' '+mobile);

      const cookies = new Cookies();
      cookies.set("DVHostName", name, { path: '/', secure: true, sameSite: "lax" });
      cookies.set("DVHostMail", mail, { path: '/', secure: true, sameSite: "lax" });
      cookies.set("DVHostMobile", mobile, { path: '/', secure: true, sameSite: "lax" });
      if (photo.length > 4096) {
        cookies.set("DVHostPhoto", '', { path: '/', secure: true, sameSite: "lax" });
      } else {
        cookies.set("DVHostPhoto", photo, { path: '/', secure: true, sameSite: "lax" });
      }
      cookies.set("DVvisitkey", guid, { path: '/', secure: true, sameSite: "lax" });
    }
  };

  onAddVisitorNameChange = (event) =>
    this.setState({
      newVisitor: { ...this.state.newVisitor, firstname: event.target.value },
    });

 
  // handleVisitType = (event) => {
  //   this.setState({ visitType: event.value });
  // }

  handleAddVisit = async (name, mail, mobile, country, key, time, visitType) => {
    const curTime = new Date().toLocaleString();
    // cookies.set("DVvisitdate", curTime, { path: '/', secure: true, sameSite: "lax" });
    // cookies.set("DVvisitreason", visitType, { path: '/', secure: true, sameSite: "lax" });
    // cookies.set("DVvisitDuration", time, { path: '/', secure: true, sameSite: "lax" });

    try {
      const params = {
        id: key,
        hostname: name,
        hostemail: mail,
        hostmobile: mobile,
        country: country,
        visitdate: curTime,
        visittime: time,
        visittype: visitType,
        username: localStorage.getItem('email'),
        usid: localStorage.getItem('usersession')
      };
      // console.log(params);

      await axios.post(`${config.api.invokeUrl}/visits/{id}`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  };

  handleError = err => {
    console.error(err)
  }

  updateInputValue(e) {
    //console.log(e.target.value);
    this.setState({
      inputValue: e.target.value,
    });

    //this.searchContacts(e.target.value);
    // this.setState({ value: e.target.value });
  }

  searchContactsClick() {
    //alert("searchContactsClick");   
    this.setState({ searchState: "Searching..." });
    this.setState({ loadingimg: "none" }, function () {
      this.setState({ loadingimg: "block" });
      this.searchContacts();
    });
  }

  searchContacts = async () => {

    this.setState({ contacts: null });
    this.setState({ loading: true });
    let objResult = '';

    const params = {
      searchString: this.state.inputValue.toLowerCase()
    };

    try {

      const res = await axios.post(`${config.api.invokeUrl}/employeesearch/`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      //console.log(res.data[0]);

      if (res.data.length === 0) {
        this.setState({ searchState: "No search results found, please try again." });
      } else {
        this.setState({ searchState: "" });
        //convert JSON object into array
        //let result = Object.values(res.data[0]);
        //console.log(result);

        //this.setState({ contactsTest: res.data[0] });
        objResult = JSON.stringify(res.data);

        //console.log(objResult);
        this.setState({ contacts: JSON.parse(objResult) });

        //console.log(this.state.contacts);
      }
      this.setState({ loadingimg: "hidden" });

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    let flagPass = true;

    //this.form.validateFields();
    //alert(this.form.isValid());

    //Manually check if duration and type exist.

    if (this.state.hostName === '') {
      this.setState({ showSearchError: 'block' });
      flagPass = false;
    } else {
      this.setState({ showSearchError: 'none' });
    }
    if ((this.state.visitDuration === '') || (this.state.visitDuration === '0:00')) {
      this.setState({ showDurationError: 'block' });
      flagPass = false;
    } else {
      this.setState({ showDurationError: 'none' });
    }
    if (this.state.visitType === '') {
      this.setState({ showReasonError: 'block' })
      flagPass = false;
    } else {
      this.setState({ showReasonError: 'none' })
    }

    if (flagPass === true) {
      //Save to the DB
      this.handleAddVisit(
        this.state.hostName,
        this.state.hostEmail,
        this.state.hostMobile,
        this.state.hostCountry,
        this.state.visitKey,
        this.state.visitDuration,
        this.state.visitType
      );

      this.setState({ redirect: true });
      // console.log(this.redirect);
    }
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            // pathname: "/visitorwelcome",
            pathname: this.state.directTo,
            state: {
              hostName: this.state.hostName,
              hostMobile: this.state.hostMobile,
              hostEmail: this.state.hostEmail,
              hostCountry: this.state.hostCountry,
              hostPhoto: cookies.get('DVHostPhoto'),
              photo: this.state.contacts.photo
            },
          }}
        />
      );
    }
    return (

      <div className="page-container">
        <div className="content-wrap">
          <Headers></Headers>
          <p></p>
          <div className="text-center">
            
            <br></br>
            
{/* 
          <Table className="table-borderless" >

          <button onClick={this.handleDelivery} type="button" className="btn btn-secondary" style={{ margin: '5px 3px 5px 0px', width:'200px', height:'50px' }}>Delivery</button>{' '}

          <button onClick={this.handleCollectionMyDelivery} type="button" className="btn btn-secondary" style={{ margin: '5px 3px 5px 0px', width:'200px', height:'50px' }}>Collect my Delivery</button>{' '}

          {/* <Button onClick={this.handleFinish} variant="primary" style={{ margin: '5px 3px 5px 0px', width: '110px', fontSize: "12px", backgroundColor: "#A8B400", borderColor: "#A8B400" }}>Finish</Button>{' '} */}

          {/* <Button onClick={this.handleCancel} variant="danger" style={{ margin: '5px 0px 5px 0px', width: '110px', fontSize: "12px" }}>Cancel</Button>{' '} */}

          {/* </Table> */} 

        <div className="container">
          <div className="form-row justify-content-center">
            <button onClick={this.handleDelivery} type="button" className="btn btn-secondary" style={{width:'200px', height:'50px', margin: "0px 0px 30px 0px" }}>Delivery</button>
          </div>
          <div className="form-row justify-content-center">
            {" "}
            <span style={{ fontSize:'18px', fontStyle:'bold'}}>OR</span>
            
            {" "}
          </div>
          <div className="form-row justify-content-center">
            <button onClick={this.handleCollectionMyDelivery} type="button" className="btn btn-secondary" style={{width:'200px', height:'50px', margin: "30px 0px 30px 0px" }}>Collect my Delivery</button>
          </div>
          <Button onClick={this.handleCancel} variant="danger" style={{ margin: '5px 0px 40px 0px', width: '110px', fontSize: "12px" }}>Cancel</Button>{' '}
        </div>


            {/* <FormWithConstraints
              ref={form => this.form = form}
              // onSubmit={this.handleSubmit}
              noValidate>
              
              

              <input
                name="visitType"
                id="visitType"
                type="text"
                onChange={() => { }}
                style={{ width: "0px", height: "1px" }}
                required
                value={this.state.visitType}
              />
            </FormWithConstraints>
            
             */}

            {/* <div className="main-footer">
              <div className="container">
                <div className="page-containerLeft">{deliveryIcon}</div>
              </div>
            </div> */}
            

            <Modal
              show={this.state.show}
              onHide={this.handleClose}
              className="text-center"
              backdrop="static"
              keyboard={false}
            >

              <Modal.Body>
                <p>
                  <b>Search Host Name or Surname</b>
                </p>
                <div className="form-group">
                  <input
                    name="searchHost"
                    id="searchHost"
                    type="text"
                    placeholder="Search"
                    className="form-control+matching-text-style"
                    style={{ width: "85%", margin: "0px 10px 0px 0px" }}
                    value={this.state.inputValue}
                    ref="searchHost"
                    onChange={(evt) => this.updateInputValue(evt)}
                    onClick={this.focusTextInput}
                    autoComplete="off"
                  />
                  <Button variant="danger" onClick={this.searchContactsClick} style={{ width: "40px", height: "30px", margin: "-10px 0px 0px 0px" }}>
                    <img
                      src={searchWhite}
                      onClick={this.searchContactsClick}
                      style={{
                        width: "20px",
                        padding: "0px 0px 0px 0px",
                        margin: "-10px 0px 0px 0px",
                        cursor: "pointer",
                      }} />
                  </Button>
                </div>
                <div className="form-group">
                  <span style={{ color: "red" }}>{this.state.searchState}</span>
                </div>
                <div>
                  <div className="tile is-4 is-parent  is-vertical" style={{ height: "300px", overflowY: "scroll" }}>
                    {this.state.contacts && Object.keys(this.state.contacts).length > 0 ? (
                      this.state.contacts.map((contact) => (
                        <Contact
                          handleSelectContact={this.handleSelectContact}
                          mail={contact.email}
                          name={contact.preferredname}
                          mobile={contact.mobile}
                          photo={contact.photo}
                          key={contact.email}
                          title={contact.title}
                        />
                      ))
                    ) : (
                      <div className="tile notification is-warning">
                        <p></p>
                      </div>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>

             <Modal
              show={this.state.showInfo}
              onHide={this.handleClose}
              className="text-center"
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  <span style={{ color: "red", fontWeight: "bold" }}>Unable to find your host?</span>
                </p>
                <p>
                  <span>Please speak to the receptionist for assistance.</span>
                </p>
                <div className="form-group">

                </div>
                <div>

                </div>
              </Modal.Body>
              <Modal.Footer >
                <Button variant="danger" onClick={this.handleClose} block>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              show={this.state.showContactError}
              onHide={this.handleClose}
              className="text-center"
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  <span style={{ color: "red", fontWeight: "bold" }}>The selected host does not have an email address specified.</span>
                </p>
                <p>
                  <span>Please speak to the receptionist for assistance.</span>
                </p>
                <div className="form-group">

                </div>
                <div>

                </div>
              </Modal.Body>
              <Modal.Footer >
                <Button variant="danger" onClick={this.handleClose} block>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Footer></Footer>

          </div>
        </div>
      </div>
    );
  }
}

export default deliveryOptionsPage;


