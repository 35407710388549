import React, { Component } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import ADMINICO from "../admin.png";
import PASSWICO from "../security.png";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Headers from "./header";


class visitorReject extends Component {
  render() {
    return (
      <div className="page-container">
        <div className="content-wrap">
          <Headers></Headers>
          <p></p>
          <div className="text-center">
            <h5>Rejected Privacy Notice</h5>
          </div>
          <Container>
            <Row>
              <Col></Col>
              <Col xs={6}>

                <div className="form-group">
                  <img src={ADMINICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                  <input
                    name="FirstName"
                    id="FirstName"
                    type="text"
                    placeholder="First Name"
                    className="form-control+matching-text-style"
                    onChange={() => { }}
                    style={{ width: '85%' }}
                  />
                </div>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col></Col>
              <Col xs={6}>

                <div className="form-group">
                  <img src={ADMINICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                  <input
                    name="Surname"
                    id="Surname"
                    type="text"
                    placeholder="Last Name"
                    className="form-control+matching-text-style"
                    onChange={() => { }}
                    style={{ width: '85%' }}
                  />
                </div>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col></Col>
              <Col xs={6}>

                <div className="form-group">
                  <img src={ADMINICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                  <input
                    name="Mobile"
                    id="Mobile"
                    type="text"
                    placeholder="Contact Number"
                    className="form-control+matching-text-style"
                    onChange={() => { }}
                    style={{ width: '85%' }}
                  />
                </div>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col></Col>
              <Col xs={6}>

                <div className="form-group">
                  <textarea>
                    This is simple textarea
                  </textarea>
                  <p />
                </div>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col></Col>
              <Col xs={6}>


                <Button href="/" variant="danger">
                  Back
                </Button>{" "}
                <Button href="visitorwelcome" variant="secondary">
                  Submit
                </Button>{" "}
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default visitorReject;