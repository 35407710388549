import React, { Component, Fragment } from 'react';
import "./dvHost.css";
import profilePic from "../profilePic.png";

export default class Contact extends Component {

  handleEditSave = event => {
    event.preventDefault();
    this.props.handleSelectContact(this.props.name, this.props.mail, this.props.mobile, this.props.photo, this.props.title);
  }

  render() {
    return (
      <div className="tile is-child box notification is-success">
        <div>
          <div id="wrapper" onClick={this.handleEditSave} style={{ cursor: "pointer" }} >
            <div id="first"><img className="img-circular" src={ this.props.photo } /></div>
            {/* {
                  this.props.photo.length === ''
                  ? <div id="first"><img className="img-circular" src={profilePic}/></div>
                  : <div id="first"><img className="img-circular" src={`data:image/jpeg;base64,${ this.props.photo }`}/></div>
                } */}
            <div id="second" >
              <div><span className="spanFont" ><b>{this.props.name}</b></span><span className="spanFont" >{', ' + this.props.title}</span></div>
              <div><span className="spanFont">{this.props.mail}</span></div>
              {/* <div><span className="spanFont">{ this.props.mobile }</span></div> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}