import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Cookies from "universal-cookie";
import axios from "axios";
import './inductionVideo.css';
import { Redirect } from "react-router";
import ReactPlayer from 'react-player';
import Table from "react-bootstrap/Table";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Footer from './footer';
//import screenfull from 'screenfull'
//import { findDOMNode } from 'react-dom'


const config = require("../config.json");

const videoFile = require("../H&S_VIDEO_2023.mp4");

const cookies = new Cookies();
let callCount = 1;

class inductionvideo extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            id: this.props.location.state.id,
            name: this.props.location.state.name,
            lastname: this.props.location.state.lastname,
            registervisitor: this.props.location.state.registervisitor,
            visitors: [],
            inductionWatched: false,
            redirect: false,
            target: "/dvHost",
            url: null,
            pip: false,
            playing: true,
            controls: true,
            light: false,
            volume: 0.8,
            muted: false,
            played: 0,
            loaded: 0,
            duration: 0,
            playbackRate: 1.0,
            loop: false,
            onEnded: false,
            currentMonth: new Date().getMonth() + 1,
            currentYear: new Date().getUTCFullYear(),
        }
    }

    // componentDidMount = () => {
        
    //     setTimeout(function () {
    //       this.fetchVisitor();
    //     }.bind(this), 5000);
    
    //     // Retry loading visitors again if empty
    //     if (this.state.visitors.length === 0) {
    //       this.fetchVisitor();
    //     }
    //   }
    
    // fetchVisitor = async () => {
    
    //     const visitKey = cookies.get('DVvisitkey');
    //     //console.log('visitKey: ' + visitKey);
    //     const params = {
    //       "newid": this.state.currentYear.toString() + (this.state.currentMonth).toString(),
    //       "visitkey": visitKey
    //     };
    //     try {
    //       const res = await axios.post(`${config.api.invokeUrl}/visitsvisitorquery`, params, {
    //         "headers": {
    //           "x-api-key": `${config.api.xapikey}`
    //         }
    //       });
    
    //       console.log(res.data);
    
    //       if (res.data.length > 0) {
    //         cookies.set('DVvisitVisitors', res.data, { path: '/', secure: true, sameSite: "lax" });
    //       }
    
    //       this.setState(
    //         {
    //           visitors: res.data,
    //           visitorsLoaded: true
    //         },
    //       );
    
    //     } catch (err) {
    //       console.log(`An error has occurred: ${err}`);
    //       this.setState({ visitorsLoaded: true });
    //     }
    
    //     if (this.state.visitors.length === 0) {

    //       callCount = callCount + 1;
    //       if (callCount < 9) {
    //         console.log(`Call fetchVisitor`);
    //         this.fetchVisitor();
    //       }
    //     }
    
    //     console.log(this.state.visitors);
        
    //   }

    handlePlayPause = () => {
        this.setState({ playing: !this.state.playing })
    }

    handleStop = () => {
        this.setState({ url: null, playing: false })
    }

    handleToggleControls = () => {
        const url = this.state.url
        this.setState({
            controls: !this.state.controls,
            url: null
        }, () => this.load(url))
    }

    handleToggleLight = () => {
        this.setState({ light: !this.state.light })
    }

    handleToggleLoop = () => {
        this.setState({ loop: !this.state.loop })
    }

    handleVolumeChange = e => {
        this.setState({ volume: parseFloat(e.target.value) })
    }

    handleToggleMuted = () => {
        this.setState({ muted: !this.state.muted })
    }

    handleSetPlaybackRate = e => {
        this.setState({ playbackRate: parseFloat(e.target.value) })
    }

    handleOnPlaybackRateChange = (speed) => {
        this.setState({ playbackRate: parseFloat(speed) })
    }

    handleTogglePIP = () => {
        this.setState({ pip: !this.state.pip })
    }

    handlePlay = () => {
        // console.log('onPlay')
        this.setState({ playing: true })
    }

    handleEnablePIP = () => {
        // console.log('onEnablePIP')
        this.setState({ pip: true })
    }

    handleDisablePIP = () => {
        // console.log('onDisablePIP')
        this.setState({ pip: false })
    }

    handlePause = () => {
        // console.log('onPause')
        this.setState({ playing: false })
    }

    handleSeekMouseDown = e => {
        this.setState({ seeking: true })
    }

    handleSeekChange = e => {
        this.setState({ played: parseFloat(e.target.value) })
    }

    handleSeekMouseUp = e => {
        this.setState({ seeking: false })
        this.player.seekTo(parseFloat(e.target.value))
    }

    handleProgress = state => {
        // console.log('onProgress', state)
        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            this.setState(state)
        }
    }

    handleEnded = () => {
        this.setState({ onEnded: true })
        this.setState({ playing: this.state.loop })

        this.setState({ inductionWatched: true });

        //update visitor induction watched
        this.updateVisitorInduction();
    }

    updateVisitorInduction = async () => {
        const curTime = new Date().toLocaleString();

        // console.log(this.state.registervisitor);

        try {
            if (this.state.registervisitor === undefined || this.state.registervisitor === null) {
                this.setState({ registervisitor: false})
            }
            
            //return;

            if (this.state.registervisitor == false) {
                // this.setState({ directTo: "/additionalVisitor" }, function () {
                this.setState({ directTo: "/dvHost" }, function () {
                });
                this.setState({ redirect: true }, function () {
                });
            } else {

                // const cookieVisitors = cookies.get('DVvisitVisitors');

                // console.log(cookieVisitors);
                // console.log(this.state.visitors);

                // const visitors = this.state.visitors;

                // if (this.state.visitors.length > 0) {
                //     var allVisitors = Object.keys(visitors).map(function(visitor) {
                //         console.log(visitor.visitorid);
                //     });
                // }
                
                // const params = {
                //     "id": this.state.id,
                //     "inductionwatched": this.state.inductionWatched,
                //     "inductiondate": curTime
                // };
                // // console.log(params);

                // await axios.patch(`${config.api.invokeUrl}/visitorinduction/`, params, {
                //     "headers": {
                //         "x-api-key": `${config.api.xapikey}`
                //     }
                // });

                // this.setState({ directTo: "/additionalVisitor" }, function () {
                this.setState({ directTo: "/dvHost" }, function () {
                });
                this.setState({ redirect: true }, function () {
                });
            }

        } catch (err) {
            console.log(`An error has occurred: ${err}`);
        }
    }

    handleDuration = (duration) => {
        // console.log('onDuration', duration)
        this.setState({ duration })
    }

    handleClickFullscreen = () => {
        // if (screenfull.isEnabled) {
        //     screenfull.request();
        // }
        //screenfull.request(findDOMNode(this.player))
    }

    renderLoadButton = (url, label) => {
        return (
            <button onClick={() => this.load(url)}>
                {label}
            </button>
        )
    }

    ref = player => {
        this.player = player
    }

    // finishedInduction = e => {
    //     this.setState({ directTo: "/additionalVisitor" }, function () {

    //     });
    //     this.setState({ redirect: true }, function () {

    //     });
    // }


    render() {
        const { url, playing, controls, light, volume, muted, loop, played, loaded, duration, playbackRate, pip } = this.state

        if (this.state.redirect) {
            return <Redirect push to={{
                pathname: this.state.target,
                state: {
                    id: this.state.id,
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    company: this.state.company,
                    image: this.state.image,
                    email: this.state.email,
                    mobile: this.state.mobile,
                    country: this.state.Country,
                    region: this.state.Region,
                    buildings: this.state.Buildings
                }
            }} />;
        }

        return (
            <div className="video-wrapper">

                <div className="text-center">
                    <ReactPlayer
                        url={videoFile}
                        // className='react-player'
                        width='100%'
                        height='100%'
                        // url={url}
                        pip={pip}
                        playing={playing}
                        controls={controls}
                        light={light}
                        loop={loop}
                        playbackRate={playbackRate}
                        volume={volume}
                        muted={muted}
                        // onReady={() => console.log('onReady')}
                        // onStart={() => console.log('onStart')}
                        onPlay={this.handlePlay}
                        onEnablePIP={this.handleEnablePIP}
                        onDisablePIP={this.handleDisablePIP}
                        onPause={this.handlePause}
                        // onBuffer={() => console.log('onBuffer')}
                        onPlaybackRateChange={this.handleOnPlaybackRateChange}
                        // onSeek={e => console.log('onSeek', e)}
                        onEnded={this.handleEnded}
                        // onError={e => console.log('onError', e)}
                        onProgress={this.handleProgress}
                        onDuration={this.handleDuration}
                    />
                </div>
            </div>

        )
    }
}

export default inductionvideo;