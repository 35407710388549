import React, { Component } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ADMINICO from "../admin.png";
import Cookies from 'universal-cookie';
import Modal from 'react-modal';
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import axios from "axios";
import { Redirect } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import Table from "react-bootstrap/Table";
import profilePic from "../profilePic.png";
import Visitor from './visitor';
import Footer from './footer';

const cookies = new Cookies();
const config = require('../config.json');
let callCount = 1;

class hostPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleClose = this.handleClose.bind(this);

    this.state = {
      redirect: false,
      buildingName: cookies.get('DVBuilding'),
      hostCountry: cookies.get("DVCountry"),
      newGuid: "",
      addVisitor: true,
      visitorExist: true,
      showMessage: false,
      visitorsLoaded: false,
      newVisitor: {
        "id": "",
        "firstname": "",
        "lastname": "",
        "company": "",
        "email": "",
        "laptop": "",
        "laptopserial": "",
        "mobile": "",
        "vehicle": "",
        "image": ""
      },
      visitors: [],
      existingvisitors: [],
      currentMonth: new Date().getMonth() + 1,
      currentYear: new Date().getUTCFullYear()
    };
  }

  handleClose() {
    this.setState({ showMessage: false });
  }

  componentDidMount = () => {
    // console.log(this.props.location.state.hostName);
    // console.log(this.props.location.state.hostMobile);
    // console.log(this.props.location.state.hostEmail);
    // console.log(this.props.location.state.hostPhoto);
    //alert(cookies.get('DVvisitkey'));
    //console.log('componentDidMount');

    const cookieVisitors = cookies.get('DVvisitVisitors');

    if (cookieVisitors === '') {
      //console.log('cookieVisitors empty');
    } else {
      this.setState(
        {
          existingvisitors: cookieVisitors,
          visitorsLoaded: true
        });
    }
    this.fetchExistingVisitor();
  }

  handleCancel = e => {
    cookies.set("DVHostPhoto", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVHostName", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVHostMail", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVHostMobile", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitkey", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitdate", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitreason", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitorType", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitVisitors", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitDuration", '', { path: '/', secure: true, sameSite: "lax" });
    this.setState({ directTo: "/dvHost" });
    this.setState({ redirect: true });
  }

  handleFinish = e => {
    this.setState({ directTo: "/additionalVisitor" });
    this.setState({ redirect: true });
  }

  handleChange = e => {
    this.setState({ newVisitor: { ...this.state.newVisitor, "id": e.target.value } })
    this.form.validateFields(e.target);
  }

  showMessageCall = () => {
    this.setState({ showMessage: true });
  };

  contactSubmit = e => {
    e.preventDefault();
    this.form.validateFields();

    if (!this.form.isValid()) {
      console.log('form is invalid: do not submit');
    } else {
      console.log('form is valid: submit');
      this.fetchVisitor()
    }
  }

  // username: localStorage.getItem('username'),
  // usid: localStorage.getItem('usersession')

  fetchVisitor = async () => {
    // console.log("fetchVisitor called");
    try {
      // Load the visitor details
      const params = {
        "id": this.state.newVisitor.id,
        "username": localStorage.getItem('username'),
        "usid": localStorage.getItem('usersession')
      };

      const res = await axios.post(`${config.api.invokeUrl}/visitor/`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });
      // console.log("LoadVisitor: " + res.data);
      this.setState({ visitors: res.data }, function () {
      });

      console.log(this.state.visitors);

      // Check if visitor already exist in the party
      let flagMatch = false;
      const visitKey = cookies.get('DVvisitkey');
      let visitorsExist = [];
      const resV = await axios.get(`${config.api.invokeUrl}/visitsvisitor/` + visitKey, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      visitorsExist = resV.data;

      cookies.set('DVvisitVisitors', resV.data, { path: '/', secure: true, sameSite: "lax" });

      let visitorParty = " ";
      visitorsExist.map((visitor, index) =>
        visitorParty = visitorParty + ' ' + visitor.visitorid + ' ');
      flagMatch = visitorParty.includes(this.state.newVisitor.id);

      if (flagMatch === true) {
        // alert("Visitor already associated to this visit");
        this.setState({ showMessage: true });
        return;
      }

      if (this.state.visitors.length == 0) {
        console.log('Unregistered');
        this.setState({ showMessage: false });
        cookies.set("DVvisitorType", 'Unregistered', { path: '/', secure: true, sameSite: "lax" });
        // this.checkVisitor();
        this.setState({ directTo: "/registerSignIn" }, function () {
          this.setState({ directTo: "/registerSignIn" });
          this.setState({ redirect: true });
        });

      } else {
        
        //visitor already exist
        console.log('Registered');
        const id = res.data[0].id;
        const name = res.data[0].firstname;
        const lastname = res.data[0].lastname;
        const image = res.data[0].image;
        const mobile = res.data[0].mobile;
        const email = res.data[0].email;
        const company = res.data[0].company;

        // get date induction was watched
        const induction = res.data[0].inductiondate;

        cookies.set("DVvisitorType", 'Registered', { path: '/', secure: true, sameSite: "lax" });
        this.setState({ showMessage: false });
        this.setState({ visitorExist: false });
        this.setState({ newVisitor: { ...this.state.newVisitor, "id": id } });
        this.setState({ newVisitor: { ...this.state.newVisitor, "firstname": name } });
        this.setState({ newVisitor: { ...this.state.newVisitor, "lastname": lastname } });
        this.setState({ newVisitor: { ...this.state.newVisitor, "email": email } });
        this.setState({ newVisitor: { ...this.state.newVisitor, "mobile": mobile } });
        this.setState({ newVisitor: { ...this.state.newVisitor, "company": company } });
        this.setState({ newVisitor: { ...this.state.newVisitor, "image": image } });

        // Dont add the visitor to the visiting party until questions are done
        // this.setState({ directTo: "/additionalQuestions" });

        const guid = uuidv4();
        this.setState({ newGuid: guid });
        this.handleAddVisitsVisitor(id, name, lastname, guid, email, company, mobile, image);

        if (induction !== '') {
          const inductionwatch = new Date(induction);
          const now = new Date();
          const sixmonthsago = 180 * 24 * 60 * 60 * 1000;
          const timeDiff = now.getTime() - inductionwatch.getTime();

          if (timeDiff >= sixmonthsago) {
            console.log('Date is older than 6 months');
            this.setState({ directTo: "/inductionVideo" });
          } else {
            console.log('Date is not older than 6 months');
            this.setState({ directTo: "/additionalVisitor" });
          }
        } else {
          this.setState({ directTo: "/inductionVideo" });
        }

        this.setState({ redirect: true });

      }
      //  console.log(res.data.length);

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  // handleSaveVisitor() {
  //   const guid = uuidv4();
  //   this.handleAddVisitsVisitor(
  //     this.state.id,
  //     this.state.firstname,
  //     this.state.lastname,
  //     guid,
  //     this.state.email,
  //     this.state.company,
  //     this.state.mobile,
  //     this.state.image);

  //   // this.setState({ directTo: "/additionalVisitor" });
  //   this.setState({ directTo: "/inductionVideo" });
  //   this.setState({ redirect: true });
  // }

  // handleAddVisitsVisitor = async (id,name,lastname,guid) => {
  //   const curTime = new Date().toLocaleString();
  //   // add call to AWS API Gateway add visitor endpoint here
  //   try {
  //       const params = {
  //           "id": guid,
  //           "visitkey": cookies.get('DVvisitkey'),
  //           "visitorid": id,
  //           "visitorname": name,
  //           "visitorlastname": lastname
  //       };
  //       await axios.post(`${config.api.visitsVisitorURL}/visitsvisitor/{id}`, params);        
  //   }catch (err){
  //       console.log(`An error has occurred: ${err}`);
  //   }    
  // }

  handleAddVisitsVisitor = async (id, name, lastname, guid, email, company, mobile, image) => {
    const curTime = new Date().toLocaleString();

    let currentTimeInMilliseconds = " ";
    currentTimeInMilliseconds = Date.now().toString(); // unix timestamp in milliseconds 

    try {
      const params = {
        "newid": this.state.currentYear.toString() + this.state.currentMonth.toString(),
        "timestamps": currentTimeInMilliseconds,
        "id": guid,
        "visitkey": cookies.get('DVvisitkey'),
        "visitorid": id,
        "visitorname": name,
        "visitorlastname": lastname,
        "visitormobile": mobile,
        "visitoremail": email,
        "visitorcompany": company,
        "visitortype": cookies.get("DVvisitorType"),
        "country": cookies.get("DVCountry"),
        "building": cookies.get("DVBuilding"),
        "visitdate": cookies.get("DVvisitdate"),
        "hostname": cookies.get("DVHostName"),
        "visitreason": cookies.get("DVvisitreason"),
        "visitorimage": image,
        "visittime": cookies.get("DVvisitDuration"),
        // username: localStorage.getItem('username'),
        username: localStorage.getItem('email'),
        usid: localStorage.getItem('usersession')
      };

      console.log(params);

      await axios.post(`${config.api.invokeUrl}/visitsvisitor/{id}`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      console.log('visitsvisitor add complete');

      // // add the latest visit key/id to visitor
      // const vparams = {
      //   "id": id,
      //   "visitkey": cookies.get('DVvisitkey'),      
      // };
      // await axios.post(`${config.api.invokeUrl}/visitorarrived/`, vparams, {
      //   "headers": {
      //     "x-api-key": `${config.api.xapikey}`
      //   }
      // });

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  fetchExistingVisitor = async () => {
    // console.log(`Call fetch existingVisitor 1`);

    callCount = callCount + 1;
    if (callCount < 9) {
      // console.log(`Call fetch existingVisitor`);
      const visitKey = cookies.get('DVvisitkey');
      // console.log('visitKey: ' + visitKey);

      try {
        const res = await axios.get(`${config.api.invokeUrl}/visitsvisitor/` + visitKey, {
          "headers": {
            "x-api-key": `${config.api.xapikey}`
          }
        });

        cookies.set('DVvisitVisitors', res.data, { path: '/', secure: true, sameSite: "lax" });

        // console.log(`Response fetchExistingVisitor: ` + res.data);

        if (!res.data.length > 0) {
          this.setState(
            {
              existingvisitors: res.data,
              visitorsLoaded: true
            },
            this.fetchExistingVisitor         // callback
          );
        }

      } catch (err) {
        // console.log(`An error has occurred: ${err}`);
        this.setState({ visitorsLoaded: true });
      }
    }
  }

  render() {
    if (this.state.showMessage) {
      return (
        <div className="text-center">
          <p>
            <span style={{ color: "red", fontWeight: "bold" }}>Visitor already associated to this visit.</span>
          </p>
          <p>
            <Button variant="secondary" onClick={this.handleClose} >
              Ok
            </Button>
          </p>
        </div>
      )
    }
    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.directTo,
        state: {
          id: this.state.newVisitor.id,
          firstname: this.state.newVisitor.firstname,
          lastname: this.state.newVisitor.lastname,
          company: this.state.newVisitor.company,
          addVisitor: this.state.addVisitor,
          image: this.state.newVisitor.image,
          email: this.state.newVisitor.email,
          mobile: this.state.newVisitor.mobile,
        }
      }} />;
    }
    return (
      <div className="text-center">

        <p>
          <h2>Welcome to {this.state.buildingName}</h2>
        </p>
        <br></br>
        <span style={{ fontSize: "16px", fontWeight: "bold" }}>Your host</span>

        <FormWithConstraints
          ref={form => this.form = form}
          onSubmit={this.contactSubmit}
          noValidate>

          <Container>
            <Row>
              <Col></Col>
              <Col xs={6}>
                <div id="wrapper" >
                  <div id="first">
                    {
                      cookies.get('DVHostPhoto') === ''
                        ? <img className="img-circular" src={profilePic} />
                        // : <img className="img-circular" src={`data:image/jpeg;base64,${cookies.get('DVHostPhoto')}`} />
                        : <img className="img-circular" src={cookies.get('DVHostPhoto')} />
                    }
                  </div>
                  <div id="second" >
                    <div><span className="spanFont"></span></div>
                    <div><span className="spanFont">{cookies.get('DVHostName')}</span></div>
                    <div><span className="spanFont"></span></div>
                  </div>
                </div>
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>Visitors</span>
                <div style={{ margin: '0px 0px 20px 0px' }}>
                  {
                    this.state.existingvisitors ?
                      this.state.existingvisitors.map((visitor, index) =>
                        <Visitor
                          visitorname={visitor.visitorname}
                          visitorlastname={visitor.visitorlastname}
                          key={visitor.id}
                        />)
                      : null
                  }
                </div>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col></Col>
              <Col xs={6}>
                <div className="form-group">
                  <img src={ADMINICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />
                  <input
                    name="search"
                    id="search"
                    type="text"
                    placeholder="Visitor ID/Passport Number"
                    className="form-control+matching-text-style"
                    required onChange={this.handleChange}
                    style={{ width: '85%' }}
                  />
                  <FieldFeedbacks for="search">
                    <FieldFeedback when="valueMissing">You cannot leave this field blank. Please capture ID/Passport number.</FieldFeedback>
                  </FieldFeedbacks>
                </div>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col></Col>
              <Col xs={8}>
                <Table className="table-borderless" >

                  <Button variant="secondary" type="submit" style={{ margin: '5px 3px 5px 0px', width: '110px', fontSize: "12px" }}>Visitor Sign-in</Button>{' '}

                  <Button href="scanQR" variant="info" style={{ margin: '5px 3px 5px 0px', width: '110px', fontSize: "12px" }}>Scan in</Button>{' '}

                  {/* <Button onClick={this.handleFinish} variant="primary" style={{ margin: '5px 3px 5px 0px', width: '110px', fontSize: "12px", backgroundColor: "#A8B400", borderColor: "#A8B400" }}>Finish</Button>{' '} */}

                  <Button onClick={this.handleCancel} variant="danger" style={{ margin: '5px 0px 5px 0px', width: '110px', fontSize: "12px" }}>Cancel</Button>{' '}

                </Table>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col></Col>
              <Col xs={6}>
                <br />
                <br />
                <br />
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </FormWithConstraints>
        <Footer></Footer>
      </div>
    );
  }
}

export default hostPage;