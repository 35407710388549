import React, { Component } from 'react';
import { Redirect } from 'react-router';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Headers from './header';
import Footers from './footer';
import profileICO from "../admin.png";
import companyICO from "../enterprise.png";
import mobileICO from "../calls-contacts.png";
import emailICO from "../mail-new.png";
import idICO from "../privacy-or-confidential.png";
import picICO from "../photos.png";
import laptopICO from "../desktop.png";
import serialICO from "../shopping-checkout.png";
import carICO from "../car.png";
import Modal from 'react-bootstrap/Modal';
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import "./register.css";
import axios from "axios";
import Table from "react-bootstrap/Table";
import StarsRating from 'stars-rating'
import Webcam from 'react-webcam';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const config = require('../config.json');
const videoConstraints = {
    facingMode: 'user'
};
let ratingValue = "0";
const ratingChanged = (newRating) => {
    ratingValue = newRating;
};


class register extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleReject = this.handleReject.bind(this);
        this.handleShowCapture = this.handleShowCapture.bind(this);

        const idLogin = this.props.location.state.id;
        const emailLogin = this.props.location.state.email;
        // console.log(emailLogin);

        this.state = {
            show: false,
            directTo: "/registerSave",
            country: cookies.get('DVCountry'),
            showCapture: false,
            screenshot: null,
            imagefield: "",
            showWebcam: "block",
            errMsg_Mobile: "none",
            errMsg_name: "none",
            errMsg_lastname: "none",
            errMsg_company: "none",
            errMsg_email: "none",
            errMsg_Image: "none",
            newVisitor: {
                "id": idLogin,
                "firstname": "",
                "lastname": "",
                "company": "",
                "email": emailLogin,
                "laptop": "",
                "laptopserial": "",
                "mobile": "",
                "vehicle": "",
                "image": ""
            },
            currentMonth: new Date().getMonth() + 1,
            currentYear: new Date().getUTCFullYear(),
            registerVisitor: false,
            registerType: "Unregistered",
            showInductionReminder: false,
            showRating: 'none',
            surveyQuestion: '',
            surveyTitle: ''
        };
    }

    screenshot() {
        // access the webcam trough this.refs
        var screenshot = this.refs.webcam.getScreenshot();
        this.setState({ screenshot: screenshot });
        this.setState({ showWebcam: "none" });
        this.setState({ imagefield: "image" });
    }

    onAddVisitorNameChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "firstname": event.target.value } });
    onAddVisitorIdChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "id": event.target.value } });
    onAddVisitorLastnameChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "lastname": event.target.value } });
    onAddVisitorCompanyChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "company": event.target.value } });
    onAddVisitorEmailChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "email": event.target.value } });
    onAddVisitorLaptopChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "laptop": event.target.value } });
    onAddVisitorLaptopserialChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "laptopserial": event.target.value } });
    onAddVisitorMobileChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "mobile": event.target.value } });
    onAddVisitorVehicleChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "vehicle": event.target.value } });

    handleClose() {
        this.setState({ show: false });
        this.setState({ showCapture: false });
        this.setState({ showMessage: false });
    }

    handleReject() {
        this.setState({ directTo: "reject" });
        this.setState({ show: false });
        this.setState({ redirect: true });
    }

    handleShow() {
        this.setState({ show: true });
        // console.log(this.state.country);
    }

    handleShowCapture() {
        this.setState({ showCapture: true });
    }

    componentDidMount = () => {
        this.setState({ screenshot: "" });
        this.setState({ newVisitor: { ...this.state.newVisitor, "laptop": " " } });
        this.setState({ newVisitor: { ...this.state.newVisitor, "laptopserial": " " } });
        this.setState({ newVisitor: { ...this.state.newVisitor, "mobile": " " } });
        this.setState({ newVisitor: { ...this.state.newVisitor, "vehicle": " " } });

        this.handleShow();
    }

    handleChange = e => {
        this.form.validateFields(e.target);
    }

    handleOk() {
        this.handleAddSurveyResult();
    
        // console.log("CLEAR Cookies");
        cookies.set("DVHostPhoto", '', { path: '/', secure: true, sameSite: "lax" });
        cookies.set("DVHostName", '', { path: '/', secure: true, sameSite: "lax" });
        cookies.set("DVHostMail", '', { path: '/', secure: true, sameSite: "lax" });
        cookies.set("DVHostMobile", '', { path: '/', secure: true, sameSite: "lax" });
        cookies.set("DVvisitkey", '', { path: '/', secure: true, sameSite: "lax" });
        cookies.set("DVvisitdate", '', { path: '/', secure: true, sameSite: "lax" });
        cookies.set("DVvisitreason", '', { path: '/', secure: true, sameSite: "lax" });
        cookies.set("DVvisitorType", '', { path: '/', secure: true, sameSite: "lax" });
        cookies.set("DVvisitVisitors", '', { path: '/', secure: true, sameSite: "lax" });
        cookies.set("DVvisitDuration", '', { path: '/', secure: true, sameSite: "lax" });
    
        this.setState({ redirect: true });
        // this.setState({ directTo: "/dvHost" });
        this.setState({ directTo: "/additionalVisitor" });
    }

    getSurvey = async () => {
        try {
          const res = await axios.get(`${config.api.invokeUrl}/surveymasters/` + this.state.hostCountry, {
            "headers": {
              "x-api-key": `${config.api.xapikey}`
            }
          });
    
          if (res.data[0].statussurvey === "Active") {
            this.setState({ showRating: "block" });
            this.setState({ surveyTitle: res.data[0].title });
            this.setState({ surveyQuestion: res.data[0].question });
          } else {
            this.setState({ surveyTitle: "" });
            this.setState({ surveyQuestion: "" });
            this.setState({ showRating: "none" });
          }
        } catch (err) {
          console.log(`An error has occurred: ${err}`);
        }
    };

    handleAddSurveyResult = async () => {
        if (this.state.showRating === "block") {
          const curTime = new Date().toLocaleString();
          const guid = uuidv4();
          const id = guid;
          let firstname = "";
          let lastname = "";
          let mobile = "";
          let email = "";
          let idno = "";
    
          // console.log(cookies.get("DVvisitorType"));
          if (cookies.get("DVvisitorType") === 'Unregistered') {
            firstname = "Anonymous";
            lastname = "Anonymous";
            mobile = "Anonymous";
            email = "Anonymous";
            idno = "Anonymous";
          } else {
            firstname = this.props.location.state.firstname;
            lastname = this.props.location.state.lastname;
            mobile = this.props.location.state.mobile;
            email = this.props.location.state.email;
            idno = this.props.location.state.id;
          }
    
          // add call to AWS API Gateway add visitor endpoint here
          let currentTimeInMilliseconds = " ";
          currentTimeInMilliseconds = Date.now().toString(); // unix timestamp in milliseconds 
          try {
            const params = {
              "newid": this.state.currentYear.toString() + this.state.currentMonth.toString(),
              "timestamps": currentTimeInMilliseconds,
              "id": guid,
              "firstname": firstname,
              "lastname": lastname,
              "mobile": mobile,
              "email": email,
              "idno": idno,
              "surveyquestion": this.state.surveyQuestion,
              "surveyresult": "" + ratingValue + "",
              "recorddate": curTime,
              "surveytitle": this.state.surveyTitle,
              "country": this.state.hostCountry,
              "username": localStorage.getItem('email'),
              "usid": localStorage.getItem('usersession')
            };
    
            // console.log(params);
            await axios.post(`${config.api.invokeUrl}/surveyresults/{id}`, params, {
              "headers": {
                "x-api-key": `${config.api.xapikey}`
              }
            });
    
          } catch (err) {
            console.log(`An error has occurred: ${err}`);
          }
        }
      }

    handleCheckChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        if (value === true) {
            this.setState({ registerVisitor: true });
        } else {
            this.setState({ registerVisitor: false });
        }
        //console.log('Save Info: ' + value);
    }

    registerSubmit = e => {
        e.preventDefault();

        let formValid = true;

        if (this.state.newVisitor.firstname === '') {
            this.setState({ errMsg_name: "block" });
            formValid = false;
        } else {
            this.setState({ errMsg_name: "none" });
        }

        if (this.state.newVisitor.lastname === '') {
            this.setState({ errMsg_lastname: "block" });
            formValid = false;
        } else {
            this.setState({ errMsg_lastname: "none" });
        }

        if (this.state.newVisitor.company === '') {
            this.setState({ errMsg_company: "block" });
            formValid = false;
        } else {
            this.setState({ errMsg_company: "none" });
        }

        // Frans - Added check only for SA
        if (this.state.country === 'South_Africa') {
            if (this.state.newVisitor.mobile.match(/^(\+\d{1,3}[- ]?)?\d{10}$/)) {
                this.setState({ errMsg_Mobile: "none" });
            } else {
                //this.setState({ newVisitor: { ...this.state.newVisitor, "mobile": "" } });
                formValid = false;
                this.setState({ errMsg_Mobile: "block" });
            }
        }

        if (this.state.screenshot === '') {
            this.setState({ errMsg_Image: "block" });
            formValid = false;
        } else {
            this.setState({ errMsg_Image: "none" });
        }

        if (formValid) {

            //console.log(this.state.screenshot);

            //Check if saved was clicked.....
            if (this.state.registerVisitor) {
                this.addVisitor();
                this.setState({ visitortype: "Registered" });
            }
            else {
                this.AddTempVisitor();
            }

            //complete adding the visit too
            this.AddVisitsVisitor();

            // this.setState({ showInductionReminder: true });

            cookies.set("DVmustwatchvideo", 'yes', { path: '/', secure: true, sameSite: "lax" });

            this.setState({ directTo: "/additionalVisitor" });
            this.setState({ redirect: true });
        }
    }

    addVisitor = async () => {
        const guid = uuidv4();
        // Update the visitor, as record already exist
        try {
            const params = {
                "id": this.state.newVisitor.id,
                "firstname": this.state.newVisitor.firstname,
                "lastname": this.state.newVisitor.lastname,
                "company": this.state.newVisitor.company,
                "email": this.state.newVisitor.email,
                "laptop": this.state.newVisitor.laptop,
                "laptopserial": this.state.newVisitor.laptopserial,
                "mobile": this.state.newVisitor.mobile,
                "vehicle": this.state.newVisitor.vehicle,
                "image": this.state.screenshot,
                "unqkey": guid,
                "username": localStorage.getItem('email'),
                "usid": localStorage.getItem('usersession')
            };

            console.log('addVisitor' + params);

            await axios.post(`${config.api.invokeUrl}/visitor/{id}`, params, {
                "headers": {
                    "x-api-key": `${config.api.xapikey}`
                }
            });

            //this.setState({ registerVisitor: true })
            //this.setState({ directTo: "/inductionVideo" });
            //this.setState({ redirect: true });

        } catch (err) {
            console.log(`An error has occurred: ${err}`);
        }

        //this.setState({ newVisitor: { "id": "", "firstname": "", "lastname": "", "company": "", "email": "", "laptop": "", "laptopserial": "", "mobile": "", "vehicle": ""}});
    }

    AddTempVisitor = async () => {
        // add call to AWS API Gateway add visitor endpoint here
        const guid = uuidv4();
        try {
            const params = {
                "unqkey": guid,
                "id": this.state.newVisitor.id,
                "firstname": this.state.newVisitor.firstname,
                "lastname": this.state.newVisitor.lastname,
                "company": this.state.newVisitor.company,
                "mobile": this.state.newVisitor.mobile,
                "visitorimage": this.state.screenshot,
                "username": localStorage.getItem('email'),
                "usid": localStorage.getItem('usersession')
            };

            // console.log(params);

            await axios.post(`${config.api.invokeUrl}/visitortemp/{unqkey}`, params, {
                "headers": {
                    "x-api-key": `${config.api.xapikey}`
                }
            });

            // this.setState({ redirect: true }, function () {
            //     this.setState({ directTo: "/registerSave" });
            // });

        } catch (err) {
            console.log(`An error has occurred: ${err}`);
        }

        //this.setState({ newVisitor: { "id": "", "firstname": "", "lastname": "", "company": "", "email": "", "laptop": "", "laptopserial": "", "mobile": "", "vehicle": ""}});
    }

    AddVisitsVisitor = async () => {
        const curTime = new Date().toLocaleString();

        let currentTimeInMilliseconds = " ";
        currentTimeInMilliseconds = Date.now().toString(); // unix timestamp in milliseconds 
        const guid = uuidv4();

        try {

            const params = {
                "newid": this.state.currentYear.toString() + this.state.currentMonth.toString(),
                "timestamps": currentTimeInMilliseconds,
                "id": guid,
                "visitkey": cookies.get('DVvisitkey'),
                "visitorid": this.state.newVisitor.id,
                "visitorname": this.state.newVisitor.firstname,
                "visitorlastname": this.state.newVisitor.lastname,
                "visitormobile": this.state.newVisitor.mobile,
                "visitoremail": this.state.newVisitor.email,
                "visitorcompany": this.state.newVisitor.company,
                "visitorimage": this.state.screenshot,
                //"visitortype": cookies.get("DVvisitorType"),
                "visitortype": this.state.registerType,
                "country": cookies.get("DVCountry"),
                "building": cookies.get("DVBuilding"),
                "visitdate": cookies.get("DVvisitdate"),
                "hostname": cookies.get("DVHostName"),
                "visitreason": cookies.get("DVvisitreason"),
                "visittime": cookies.get("DVvisitDuration"),
                "username": localStorage.getItem('email'),
                "usid": localStorage.getItem('usersession')
            };

            console.log(params);

            // await axios.post(`${config.api.invokeUrl}/visitsvisitor/{id}`, params, {
            //     "headers": {
            //         "x-api-key": `${config.api.xapikey}`
            //     }
            // });

            await axios
            .post(`${config.api.invokeUrl}/visitsvisitor/{id}`, params, {
              "headers": {
                "x-api-key": `${config.api.xapikey}`
              }
            })
            .then(data => {
              console.log('handleAddVisitsVisitor complete');
            })
            .catch(err => {
              console.log(err);
              return null;
            });

            //   this.setState({ directTo: "/inductionVideo" });
            //   this.setState({ redirect: true });

        } catch (err) {
            console.log(`An error has occurred: ${err}`);
        }
    }

    // handleAddVisitsVisitor = async (id, name, lastname) => {
    //     const curTime = new Date().toLocaleString();
    //     const guid = uuidv4();
    //     // add call to AWS API Gateway add visitor endpoint here
    //     try {
    //         const params = {
    //             "id": guid,
    //             "visitkey": cookies.get('DVvisitkey'),
    //             "visitorid": id,
    //             "visitorname": name,
    //             "visitorlastname": lastname,
    //             "username": localStorage.getItem('email'),
    //             "usid": localStorage.getItem('usersession')
    //         };

    //         await axios.post(`${config.api.invokeUrl}/visitsvisitor/{id}`, params, {
    //             "headers": {
    //                 "x-api-key": `${config.api.xapikey}`
    //             }
    //         });

    //     } catch (err) {
    //         console.log(`An error has occurred: ${err}`);
    //     }
    // }

    render() {
        if (this.state.redirect) {
            return <Redirect push to={{
                pathname: this.state.directTo,
                state: {
                    id: this.state.newVisitor.id,
                    email: this.state.newVisitor.email,
                    firstname: this.state.newVisitor.firstname,
                    lastname: this.state.newVisitor.lastname,
                    company: this.state.newVisitor.company,
                    mobile: this.state.newVisitor.mobile,
                    image: this.state.screenshot
                }
            }} />;
        }
        return (
            <div className="page-container">
                <div className="content-wrap">
                    <Headers></Headers>

                    <div className="text-center">
                        <h3 className="mainTitleText" style={{ margin: '50px 0px 0px 0px' }}>Your details are required to complete your sign in </h3>
                        <br></br>
                        <FormWithConstraints
                            ref={form => this.form = form}
                            onSubmit={this.registerSubmit}
                            noValidate>
                            <div className="container">
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <img src={profileICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                        <input
                                            name="name"
                                            id="name"
                                            type="text"
                                            placeholder="First Name *"
                                            className="form-control+matching-text-style"
                                            required
                                            style={{ width: '85%' }}
                                            value={this.state.newVisitor.firstname}
                                            onChange={this.onAddVisitorNameChange}
                                            autoComplete="off"
                                        />
                                        <div style={{ display: this.state.errMsg_name, color: 'red' }}><p>First Name is mandatory, please complete</p></div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <img src={profileICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                        <input
                                            name="surname"
                                            id="surname"
                                            type="text"
                                            placeholder="Last Name *"
                                            className="form-control+matching-text-style"
                                            required
                                            style={{ width: '85%' }}
                                            value={this.state.newVisitor.lastname}
                                            onChange={this.onAddVisitorLastnameChange}
                                            autoComplete="off"
                                        />
                                        <div style={{ display: this.state.errMsg_lastname, color: 'red' }}><p>Last Name is mandatory, please complete</p></div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <img src={companyICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                        <input
                                            name="Company"
                                            id="Company"
                                            type="text"
                                            placeholder="Company Name *"
                                            className="form-control+matching-text-style"
                                            value={this.state.newVisitor.company}
                                            onChange={this.onAddVisitorCompanyChange}
                                            style={{ width: '85%' }}
                                            required
                                            autoComplete="off"
                                        />
                                        <div style={{ display: this.state.errMsg_company, color: 'red' }}><p>Company name is mandatory, please complete</p></div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <img src={mobileICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                        <input
                                            name="Mobile"
                                            id="Mobile"
                                            type="text"
                                            placeholder="Contact Number *"
                                            className="form-control+matching-text-style"
                                            value={this.state.newVisitor.mobile}
                                            onChange={this.onAddVisitorMobileChange}
                                            style={{ width: '85%' }}
                                            required
                                            autoComplete="off"
                                        />
                                        <div style={{ display: this.state.errMsg_Mobile, color: 'red' }}><p>Invalid number captured, please complete</p></div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <img src={idICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                        <input
                                            name="Id"
                                            id="Id"
                                            type="text"
                                            placeholder="RSA ID/Passport number"
                                            className="form-control+matching-text-style"
                                            required
                                            style={{ width: '85%' }}
                                            value={this.state.newVisitor.id}
                                            readOnly
                                            autoComplete="off"
                                        />
                                        <FieldFeedbacks for="Id">
                                            <FieldFeedback when="*" />
                                        </FieldFeedbacks>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <img src={picICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                        <input
                                            name="avatar"
                                            id="avatar"
                                            type="text"
                                            placeholder="Capture Profile Image *"
                                            className="form-control+matching-text-style"
                                            onChange={() => { }}
                                            style={{ width: '85%' }}
                                            onClick={this.handleShowCapture}
                                            value={this.state.imagefield}

                                        />
                                        <div style={{ display: this.state.errMsg_Image, color: 'red' }}><p>Image is mandatory, please complete</p></div>
                                    </div>
                                </div>

                                <div className="text-center">
                                    <input type="checkbox"
                                        name="saveinfo"
                                        id="saveinfo"
                                        style={{ margin: '0px 5px 0px 0px' }}
                                        onChange={(e) => { this.handleCheckChange(e) }}
                                        required
                                    >
                                    </input><span>Save information for easier access next time I visit</span>
                                    <br />
                                    <button style={{ width: '200px', margin: '20px 0px 90px 0px' }} className="btn btn-secondary">
                                        Next
                                    </button>
                                </div>

                                {/* <div className="text-center">
                                    <button style={{ margin: '0px 0px 70px 0px' }} className="btn btn-secondary" >Next</button>
                                </div> */}

                            </div>
                        </FormWithConstraints>



                    </div>
                </div>


                <Modal show={this.state.showCapture}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false}
                    style={{ textAlign: "center" }}>

                    <Modal.Body style={{ textAlign: "center" }}>

                        <Webcam
                            audio={false}
                            ref='webcam'
                            screenshotFormat="image/jpeg"
                            height={"420px"}
                            width={"470px"}
                            facingMode="user"
                            style={{ display: this.state.showWebcam }}
                            videoConstraints={videoConstraints} />

                        {this.state.screenshot ? <img src={this.state.screenshot} /> : null}

                        <br />
                        <br />
                        <button style={{ margin: '0px 10px 0px 0px', width: "100px" }} className="btn btn-info" onClick={this.screenshot.bind(this)}>Capture</button>
                        <Button style={{ margin: '0px 10px 0px 0px', width: "100px" }} variant="secondary" onClick={this.handleClose}>
                            Save
                        </Button>
                        <Button style={{ width: "100px" }} variant="danger" onClick={this.handleClose}>
                            Cancel
                        </Button>
                    </Modal.Body>

                </Modal>



                <Modal show={this.state.show}
                    onHide={this.handleClose}
                    className="text-center"
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header>
                        <Modal.Title><h2>Privacy Notice</h2></Modal.Title>
                    </Modal.Header>
                    <Modal.Body><p><b>Privacy Notice: Digital Visitor Application</b></p>
                        <span style={{ fontSize: "12px" }}>By accepting the Digital Visitor Privacy Notice you acknowledge and agree that Pernod Ricard is required to collect your personal information  including your name, surname, contact details, identity number, email address, photo, as well as your designated company’s information (where applicable) in order to allow you to gain access to our buildings. The information provided herein will be processed in accordance with the prevailing privacy legislations in South Africa and Pernod Ricard’s Data Protection and Privacy Policy. By registering and submitting the required information you acknowledge and agree that your personal information may be processed for access purposes only to any of Pernod Ricard’s buildings. Your personal information will not be disclosed to any unauthorized third party and/or outside of the country without your knowledge and consent. You hereby further acknowledge and agree that the processing of your personal information might be shared with other Pernod Ricard business units for historic and statistic purposes, where necessary. Failure to accept this privacy notice will result in the prohibition of access to any Pernod Ricard building.</span>
                        <p style={{ fontSize: "12px" }}>Please refer to our privacy statement for more information.</p>
                        <p></p>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Accept
                        </Button>
                        <Button variant="danger" onClick={this.handleReject}>
                            Reject
                        </Button>

                    </Modal.Footer>
                </Modal>


                {/* <Modal
                    show={this.state.showInductionReminder}
                    onHide={this.handleClose}
                    className="text-center"
                    backdrop="static"
                    keyboard={false}>

                    <Modal.Body>
                        <br />
                        <br />
                        <p>
                            <span className="subTitleText">Please stay close to watch the health and safety video.</span>
                        </p>
                        <Table className="table-borderless" style={{ width: "100%", textAlign: "center" }}>
                            <tbody>
                                <tr>
                                    <td colSpan="3">
                                        {this.state.surveyQuestion}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%", textAlign: "center" }}></td>
                                    <td style={{ width: "50%", textAlign: "center" }}>
                                        <div style={{ display: this.state.showRating }}>
                                            <StarsRating
                                                count={10}
                                                onChange={ratingChanged}
                                                size={24}
                                                color2={'#ffd700'} />
                                        </div>
                                    </td>
                                    <td style={{ width: "25%", textAlign: "center" }}></td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer >
                        <Button variant="secondary" onClick={this.handleOk}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal> */}
                
                <Footers></Footers>
            </div>
        );
    }
}

export default register;