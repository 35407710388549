import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Headers from './components/header';
import PasswordReset from './components/passwordReset';
import PasswordReq from './components/passwordRequest';
import Footer from './components/footer';
import Logon from './components/loginFields';
import OTP from './components/otpFields';
import otpPref from './components/otpPref';
import RegionSel from './components/regionSelect';
import HostPage from './components/dvHost';
//import dvHosts from './components/dvHosts';
import WelcomPage from './components/visitorwelcome';
import ScanCode from './components/scanQR';
import registerVisitor from './components/visitorRegister';
import rejectVisitor from './components/visitorReject';
import test from './components/test';
import test1 from './components/test1';
import test2 from './components/test2';
import test3 from './components/test3';
import otpPassword from './components/otpPassword';
import additionalVisitor from './components/additionalVisitor';
import register from './components/register';
import registerSignin from './components/registerSignIn';
import reject from './components/reject';
import questions from './components/additionalQuestions';
import regsave from './components/registerSave';
import otpregister from './components/otpRegister';
import inductionVideo from './components/inductionVideo';
import visitorLeaving from './components/visitorLeaving';
import delivery from './components/delivery';
import deliveryDetails from './components/deliveryDetails';
import deliveryorCollection from './components/deliveryorcollection';



function App() {
  return (
    <Router>
      {/* <div className="page-container">
        <div className="content-wrap">
          <Headers></Headers>
          <p></p> */}
          <Switch>
            <Route exact path='/' component={Logon} />
            <Route path='/PasswordReset' component={PasswordReset} />
            <Route path='/otpFields' component={OTP} />
            <Route path='/passwordRequest' component={PasswordReq} />
            <Route path='/regionSelect' component={RegionSel} />
            <Route path='/dvHost' component={HostPage} />
            <Route path='/visitorwelcome' component={WelcomPage} />
            <Route path='/scanQR' component={ScanCode} />
            <Route path='/loginFields' component={Logon} />
            <Route path='/visitorRegister' component={registerVisitor} />
            <Route path='/visitorReject' component={rejectVisitor} />
            <Route path='/test' component={test} />
            <Route path='/test1' component={test1} />
            <Route path='/test2' component={test2} />
            <Route path='/test3' component={test3} />
            <Route path='/otpPref' component={otpPref} />
            <Route path='/otpPassword' component={otpPassword} />
            <Route path='/additionalVisitor' component={additionalVisitor} />
            <Route path='/register' component={register} />
            <Route path='/registerSignIn' component={registerSignin} />
            <Route path='/reject' component={reject} />
            <Route path='/additionalQuestions' component={questions} />
            <Route path='/registerSave' component={regsave} />
            <Route path='/otpRegister' component={otpregister} />
            <Route path='/inductionVideo' component={inductionVideo} />
            <Route path='/visitorLeaving' component={visitorLeaving} />
            <Route path='/delivery' component={delivery} />
            <Route path='/deliveryDetails' component={deliveryDetails} />
            <Route path='/deliveryorcollection' component={deliveryorCollection} />
          </Switch>
        {/* </div>
      </div> */}
    </Router>
  );
}

export default App;
