import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OTPClient from 'otp-client';
import OtpInput from 'react-otp-input';
import Footers from './footer';
import axios from 'axios';
import './otpFields.css';
import { Redirect } from 'react-router';
import spacer from "../spacer.png";
import Headers from './header';

const config = require('../config.json');

const secret = 'TPQDAHVBZ5NBO5LFEQKC7V7UPATSSMFY';
const otp = new OTPClient(secret);
const token = otp.getToken();

class otpPref extends Component {
  constructor(props, context) {
    super(props, context);

    // const LoginId = this.props.location.state.id;
    // const LoginMobile = this.props.location.state.mobile;
    // const LoginEmail = this.props.location.state.email;

    this.handleCancel = this.handleCancel.bind(this);

    this.state = {
      otp: '',
      redirect: false,
      Id: this.props.location.state.id,
      Mobile: this.props.location.state.mobile,
      Email: this.props.location.state.email,
      Mode: this.props.location.state.mode,
      Country: this.props.location.state.country,
      Region: this.props.location.state.region,
      Buildings: this.props.location.state.buildings,
      //target: this.props.location.state.target,
      target: "/regionSelect",
      showOTPError: "none",
      numInputs: 6,
      separator: '',
      isDisabled: false,
      hasErrored: false,
      isInputNum: false,
      minLength: 0,
      maxLength: 40,
      newOTP: '',
      failedLogins: 1
    };
  }

  handleOtpChange = otp => {
    this.setState({ otp });
  };

  handleCancel() {
    this.setState({ redirect: true });
    this.setState({ target: "/" });
  }

  handleChange = e => {
    let currVal = e.target.value;

    if (e.target.name === 'numInputs') {
      const { minLength, maxLength } = this.state;

      if (currVal < minLength || currVal > maxLength) {
        currVal = 4;

        console.error(
          `Please enter a value between ${minLength} and ${maxLength}`
        );
      }
    }
    this.setState({ [e.target.name]: currVal });
  };

  clearOtp = () => {
    this.setState({ otp: '' });
  };

  handleCheck = e => {
    const { name } = e.target;
    this.setState(prevState => ({ [name]: !prevState[name] }));
  };

  handleSubmit = e => {
    e.preventDefault();
    //alert(this.state.otp);
    //alert(this.state.newOTP);
    if (this.state.otp == this.state.newOTP) {
      this.setState({ redirect: true });
      this.setState({ target: "/regionSelect" });
    } else {
      this.setState({ failedLogins: this.state.failedLogins + 1 });
      this.setState({ showOTPError: "block" });
      this.setState({ hasErrored: true });
      if (this.state.failedLogins > 2) {
        this.setState({ target: "/loginFields" })
        this.setState({ redirect: true });
      }
    }
  };

  componentDidMount = () => {
    console.log(this.state.Mode);

    this.sendEmailMessage();
    this.sendSMSMessage();

    // if ("Email" == this.state.Mode) {
    //   this.sendEmailMessage();
    // }
    // if ("Mobile" == this.state.Mode) {
    //   this.sendSMSMessage();
    // }
  }

  resendOTP = e => {
    e.preventDefault();

    this.sendEmailMessage();
    this.sendSMSMessage();

    // if ("Email" == this.state.Mode) {
    //   this.sendEmailMessage();
    // }
    // if ("Mobile" == this.state.Mode) {
    //   this.sendMessage();
    // }
  };

  sendMessage = () => {
    const secret = 'TPQDAHVBZ5NBO5LFEQKC7V7UPATSSMFY';
    const otp = new OTPClient(secret);
    const token = otp.getToken();

    this.setState({ newOTP: token }, function () {
      console.log(this.state.newOTP);
      // console.log(this.state.Mobile);
    });

    var data = JSON.stringify({
      "message": "Pernod Ricard Digital Visitor " + token,
      "token": `${config.api.SMStoken}`,
      "destination": this.state.Mobile
    });

    // Temp until we have a message sender
    this.setState({ directTo: "/regionSelect" })

    // var config = {
    //   method: 'post',
    //   url: `${configFile.api.SMSUrl}`,
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Headers": "Content-Type,X-Api-Key",
    //     "Access-Control-Allow-Methods": "OPTIONS,POST,PUT,GET,PATCH"
    //   },
    //   data: data
    // };

    // axios(config)
    //   .then(function (response) {
    //     // console.log(JSON.stringify(response.data));
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }

  sendEmailMessage = async () => {
    // const secret = 'TPQDAHVBZ5NBO5LFEQKC7V7UPATSSMFY';
    // const otp = new OTPClient(secret);
    // const token = otp.getToken();
    const emailBody = "<p>Dear Administrator<p /><span>You have requested a Login OTP.</span><br/><span style='text-align:centre'>Please use the below OTP number in the reception interface:</span><p style='font-size:14px'><b>" + token + "</b></p><br /><span style='text-align:left'>Regards</span><br/><span>Digital Visitors Team</span>";

    this.setState({ newOTP: token }, function () {
      console.log(this.state.newOTP);
    });

    const params = {
      "toaddress": this.state.Id.toLowerCase(),
      "subject": "Pernod Ricard Digital Visitor Reception",
      "body": emailBody
    };
    //console.log(params);

    try {
      const res = await axios.post(`${config.api.invokeUrl}/email/`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      if (res.status == "200") {
        // this.setState({ redirect: true });
      } else {
        console.log(`An error has occurred trying to send email`);
      }

    } catch (err) {
      console.log(`An error has occurred trying to send email: ${err}`);
    }
  }

  sendSMSMessage = async () => {

  
    this.setState({ newOTP: token }, function () {
      console.log(this.state.newOTP);
    });

    // const smsBody = "Dear " + hostName + ", Please be advised that " + visitorParty + " has arrived at " + building + " Reception Area.";
    // const smsBody = "You have requested a Login OTP. Please use OTP number in the reception interface: " + token + ". Regards, Digital Visitors Team";

    const smsBody = "Pernod Ricard Digital Visitor Reception login: " + token;

    const params = {
      "tomobile": this.state.Mobile,
      "body": smsBody
    };

    console.log(params);

    try {
      const res = await axios.post(`${config.api.invokeUrl}/sms/`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`,
          'Content-Type': 'application/json'
        }
      });

      if (res.status === "201") {
        // this.setState({ redirect: true });
      } else {
        console.log(`An error has occurred trying to send sms`);
      }

    } catch (err) {
      console.log(`An error has occurred trying to send sms: ${err}`);
    }
  }

  //OTP has been sent to {this.state.Mobile.substring(0,8)}**** or email address {this.state.Email.substring(0,15)}*****{(this.state.Email.split('@')[1]).split('.')[1]} for registered Admin

  render() {
    const {
      otp,
      numInputs,
      separator,
      isDisabled,
      hasErrored,
      isInputNum,
      minLength,
      maxLength,
      newOTP,
    } = this.state;

    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.target,
        state: {
          id: this.state.Id,
          country: this.state.Country,
          region: this.state.Region,
          buildings: this.state.Buildings
        }
      }} />;
    }
    return (
      <div className="page-container">
        <div className="content-wrap">
          <Headers></Headers>
          <p></p>
          <div className="text-center" style={{ margin: '30px 0px 0px 0px' }}>
            <h2 className="dvHeaderText">OTP has been sent to email address for registered Administrator</h2>
            <p><span className="dvHeaderText"><b>Capture OTP</b></span></p>

            <p>
              <span style={{ display: this.state.showOTPError, color: 'red' }}>Incorrect OTP captured, please click resend and try again</span>
            </p>

            <form onSubmit={this.handleSubmit}>
              <div className="containerOTP">
                <div className="view">
                  <div className="card">
                    <OtpInput
                      inputStyle={{
                        width: "3rem",
                        height: "3rem",
                        margin: "5px",
                        fontSize: "1rem",
                        borderRadius: 4,
                        border: "2px solid rgba(0,0,0,0.3)",
                      }}
                      numInputs={numInputs}
                      isDisabled={isDisabled}
                      hasErrored={hasErrored}
                      errorStyle="error"
                      onChange={this.handleOtpChange}
                      separator={<span>{separator}</span>}
                      isInputNum={isInputNum}
                      shouldAutoFocus
                      value={otp}
                    />
                    <div >
                      <button
                        style={{ margin: '10px 0px 0px 0px' }}
                        className="btn btn-secondary"
                        disabled={otp.length < numInputs}>
                        Submit
                      </button>
                      <button
                        style={{ margin: '10px 0px 0px 10px' }}
                        className="btn btn-danger"
                        onClick={this.handleCancel}>
                        Cancel
                      </button>
                      <br />
                      <button style={{ margin: '10px 0px 70px 0px', textDecoration: 'underline', fontWeight: 'bold', color: '#4A4D4E' }} className="btn" onClick={this.resendOTP} >Resend OTP</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <br />

          <div className="main-footer">
            <div className="container">
              <img src={spacer}></img>
            </div>
            <div className="page-containerRight"></div>
          </div>

        </div>
      </div>

    );
  }
}

export default otpPref;