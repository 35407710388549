import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OTPClient from 'otp-client';
import OtpInput from 'react-otp-input';
import Headers from './header';
import Footers from './footer';
import axios from 'axios';
import './otpFields.css';
import { Redirect } from 'react-router';
import spacer from "../spacer.png";

const config = require('../config.json');

class otpPref extends Component {   
    constructor(props, context) {
		super(props, context);

        const LoginId = this.props.location.state.id;
        const LoginMobile = this.props.location.state.mobile;
        const LoginEmail = this.props.location.state.email;

        this.handleCancel = this.handleCancel.bind(this);

        // console.log(this.props.location.state.mode);
        // console.log(this.props.location.state.id);
    
        this.state = {
            otp: '',
            redirect: false,
            Id: this.props.location.state.id,
            Mobile: this.props.location.state.mobile,
            Email: this.props.location.state.email,
            Mode: this.props.location.state.mode,
            country: this.props.location.state.country,
            buildingsregion: this.props.location.state.buildingsregion, 
            userrole: this.props.location.state.userrole, 
            buildings: this.props.location.state.buildings,
            numInputs: 6,
            separator: '',
            isDisabled: false,
            hasErrored: false,
            isInputNum: false,
            minLength: 0,
            maxLength: 40,
            newOTP: '',
            showOTPError: "none",
            submitCount: 1,
            pathname: "/passwordReset"
          };  

        }
      
        handleOtpChange = otp => {
          this.setState({ otp });
        };
      
        handleChange = e => {
          let currVal = e.target.value;
      
          if (e.target.name === 'numInputs') {
            const { minLength, maxLength } = this.state;
      
            if (currVal < minLength || currVal > maxLength) {
              currVal = 4;
      
              console.error(
                `Please enter a value between ${minLength} and ${maxLength}`
              );
            }
          }
      
          this.setState({ [e.target.name]: currVal });
        };
      
        clearOtp = () => {
          this.setState({ otp: '' });
        };
      
        handleCheck = e => {
          const { name } = e.target;
          this.setState(prevState => ({ [name]: !prevState[name] }));
        };
      
        handleSubmit = e => {
          e.preventDefault();
          //alert(this.state.otp);
          //alert(this.state.newOTP);
          // console.log(this.state.Id);
          if (this.state.otp == this.state.newOTP) {
            this.setState({ redirect: true });
          } else {
            this.setState({ showOTPError: "block"});   
            this.setState({ hasErrored: true });
            this.setState({ submitCount: this.state.submitCount + 1});
            //alert(this.state.submitCount);
            if (this.state.submitCount == 3) {
                // lock the account and redirect to login page
                this.setState({ pathname: "/" });
                this.setState({ redirect: true });
            }
          }
        };
      
        componentDidMount = () => {
        //   console.log(this.state.Mode);
        //   console.log(this.state.Mobile);
        //   console.log(this.state.Email);
          if ("Email" == this.state.Mode)    {
            this.sendEmailMessage();
          }    
          if ("Mobile" == this.state.Mode)    {
            this.sendMessage();
          }            
         }

         resendOTP  = e => {
          e.preventDefault();
          if ("Email" == this.state.Mode)    {
            this.sendEmailMessage();
          }    
          if ("Mobile" == this.state.Mode)    {
            this.sendMessage();
          } 
        };

      handleCancel() {
          this.setState({ redirect: true });
          this.setState({ pathname: "/" });
      }
      
      sendMessage = () => {
          const secret = 'TPQDAHVBZ5NBO5LFEQKC7V7UPATSSMFY';
          const otp = new OTPClient(secret);
          const token = otp.getToken();
          
          this.setState({newOTP: token}, function () {
              // console.log(this.state.newOTP);
          });
          
      
          var data = JSON.stringify({"message": "Pernod Ricard Digital Visitor " + token,
              "token": `${config.api.SMStoken}`,                  
              "destination": this.state.Mobile});
      
          var config = {
            method: 'post',
            url: `${config.api.SMSUrl}`,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            // console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
          });
      
      }

      sendEmailMessage = async () => {
        const secret = 'TPQDAHVBZ5NBO5LFEQKC7V7UPATSSMFY';
        const otp = new OTPClient(secret);
        const token = otp.getToken();
        const emailBody = "<p>Dear Administrator<p/><span>You have requested a password reset.</span><br/><span>Kindly reset your password by capturing the below OTP number in the reception interface:</span><p><b>" + token + "</b></p><br /><span>Regards</span><br/><span>Digital Visitors Team</span>";

        this.setState({newOTP: token}, function () {
         //   console.log(this.state.newOTP);
        });
    
        const params = {
          "toaddress": [this.state.Email],
          "subject": "Pernod Ricard Digital Visitors OTP",
          "body": emailBody
        };
        //console.log(params);
    
        try {
          const res = await axios.post(`${config.api.invokeUrl}/email/`, params, {
            "headers": {
              "x-api-key": `${config.api.xapikey}`
            }
          });
    
          if (res.status == "200") {       
            // this.setState({ redirect: true });
          } else {
            console.log(`An error has occurred trying to send email`);
          }
    
        } catch (err) {
          console.log(`An error has occurred trying to send email: ${err}`);
        }
    
    }
      
    render() {
          const {
            otp,
            numInputs,
            separator,
            isDisabled,
            hasErrored,
            isInputNum,
            minLength,
            maxLength,
            newOTP,
          } = this.state;

        if (this.state.redirect) {
            return <Redirect push to={{
                pathname: this.state.pathname, 
                state: { id: this.state.Id,
                    mobile: this.state.Mobile,
                    email: this.state.Email,
                    country: this.state.country,
                    buildingsregion: this.state.buildingsregion,
                    userrole: this.state.userrole,
                    buildings: this.state.buildings}
              }} />;
        }
        return ( 
            <div>      
                <div className="text-center" style={{ margin: '30px 0px 0px 0px' }}>
                <p>
                <span style={{ fontSize: '20pt' }}>An OTP was sent to the mobile number or email address provided.</span>  
                <span style={{ display: this.state.showOTPError, color: 'red' }}>Please click resend and try again or contact DV support team on 082 191 or VisitorsAppSupport@exponant.com</span>                            
                </p>                                       
            <br/>
            <p/><h3>Capture OTP</h3> 
            <form onSubmit={this.handleSubmit}>
            <div className="containerOTP"> 
                <div className="view">
                    <div className="card">
                        <OtpInput
                            inputStyle={{
                                width: "3rem",
                                height: "3rem",
                                margin: "5px",
                                fontSize: "1rem",
                                borderRadius: 4,
                                border: "2px solid rgba(0,0,0,0.3)",
                                }}
                            numInputs={numInputs}
                            isDisabled={isDisabled}
                            hasErrored={hasErrored}
                            errorStyle="error"
                            onChange={this.handleOtpChange}
                            separator={<span>{separator}</span>}
                            isInputNum={isInputNum}
                            shouldAutoFocus
                            value={otp}
                        />
                        <div >
                            <button 
                            style={{margin: '10px 0px 0px 0px'}} 
                            className="btn btn-secondary" 
                            disabled={otp.length < numInputs}>
                            Submit
                        </button>    
                        <button 
                            style={{margin: '10px 0px 0px 10px'}} 
                            className="btn btn-danger"
                            onClick={this.handleCancel}>                             
                            Cancel
                        </button> 
                        <br/>                    
                        <button style={{margin: '10px 0px 70px 0px', textDecoration: 'underline', fontWeight: 'bold', color: '#4A4D4E' }} className="btn" onClick={this.resendOTP} >Resend OTP</button>                                        
                        </div>
                        
                    </div>                    
                </div>
                
            </div> 
            </form>
                      
                    

           
                </div>
            
          
            <br/>

            <div className="main-footer">
                 <div className="container">
                     <img src={spacer}></img>
                 </div>
                  <div className="page-containerRight"></div>           
                </div>
            
        </div>              
            );
    }
}
 
export default otpPref;