import React, { Component } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import ADMINICO from "../admin.png";
import DURATION from "../clock-or-timed.png";
import SEARCHICO from "../search.png";
import HELPICO from "../help-circle.png";
import profilePic from "../profilePic.png";
import INFO from "../info-circle red.png";
import searchWhite from "../searchWhite.png";
import deliveryIcon from "../delivery.png";
import LOADING from "../tenor.gif";
import Cookies from "universal-cookie";
import Modal from "react-bootstrap/Modal";
import Contact from "./contact";
import axios from "axios";
import { Redirect } from "react-router";
import { v4 as uuidv4 } from "uuid";
import {
  FormWithConstraints,
  FieldFeedbacks,
  Async,
  FieldFeedback,
} from "react-form-with-constraints";
import Table from "react-bootstrap/Table";
import Headers from './header';
import Footer from './footer';
import { search } from '../utils'
import { Icon } from '@iconify/react';

const config = require("../config.json");
const cookies = new Cookies();

const deliveryOptions = ['Aramex', 'Courier Guy', 'DHL', 'Mr Delivery', 'RAM', 'Other'];
let otherName = '';

class deliveryPage extends Component {
  constructor(props, context) {
    super(props, context);

    // this.handleShow = this.handleShow.bind(this);
    // this.handleClose = this.handleClose.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.state = {
      courierCompany: "",
      courierOtherName: "",
      loadingimg: "hidden",
      // directTo: "/visitorwelcome",
      inputValue: "",
      redirect: false,
      showOther: false,
      errMsg_othername: 'none',
      btnsDisabled: true
    };

    this.focusTextInput = () => {
      // Focus the text input using the raw DOM API
      if (this.textInput) this.textInput.focus();
    };

  }

  componentDidMount = () => {
    cookies.set("courierCompany", '', { path: '/', secure: true, sameSite: "lax" });
    // this.setState({ buildingName: cookies.get("DVBuilding") });
    

  };

  onOtherChange = e => { 

    this.setState({ courierOtherName: e.target.value });
    // this.setState({ courierCompany: e.target.value });

    // otherName = e.target.value;

    // if (e.target.value === '') {
    //   this.setState({ btnsDisabled : true })
    //   this.setState({ errMsg_othername: "block" });
    // } else {
    //   this.setState({ btnsDisabled : false })
    //   this.setState({ errMsg_othername: "none" });
    // }
  }

  removeUnderscore(stringfromdb) {
    var string = stringfromdb;
    string = string.replace(/_/g, " ");
    return string;
  }

  handleClose() {
    this.setState({ show: false });
    this.setState({ showInfo: false });
    this.setState({ showContactError: false });

  }

  // handleShow() {
  //   this.setState({ show: true });
  //   this.focusTextInput();
  // }

  handleButtonClick(event) {
    
    if (event !== '')
    {
      this.setState({ btnsDisabled : false })
      this.setState({ courierCompany: event });

      if (event === 'Other') {    
        this.setState({ showOther: true });
      }
      else {
        this.setState({ showOther: false });
        
      }
    }
  }

  handleContinue = e => {
    e.preventDefault();
    let formValid = true;

    if (this.state.courierCompany === 'Other') {
      if (this.state.courierOtherName !== '') {
        otherName = this.state.courierOtherName;
        this.setState({ courierCompany: this.state.courierCompany+" ("+otherName+")" }, function () {
          console.log(this.state.courierCompany);
          cookies.set("courierCompany", this.state.courierCompany, { path: '/', secure: true, sameSite: "lax" });
        });
        
        this.setState({ errMsg_othername: "none" });
      }
      else {
        // Not other or courier company selected
        this.setState({ btnsDisabled : false })
        this.setState({ errMsg_othername: "block" });
        formValid = false;
      }
    }
    else {
      cookies.set("courierCompany", this.state.courierCompany, { path: '/', secure: true, sameSite: "lax" });
    }

    

    if (formValid) {

      
      this.setState({ directTo: "/deliveryDetails" });
      this.setState({ redirect: true });
    }
  }

  handleCancel = e => {
    this.setState({ directTo: "/dvHost" });
    this.setState({ redirect: true });
  }

  handleError = err => {
    console.error(err)
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            // pathname: "/visitorwelcome",
            pathname: this.state.directTo,
            state: {
              courier: otherName === "" ? this.state.courierCompany+" ("+otherName+")" : otherName
            },
          }}
        />
      );
    }
    return (

      <div className="page-container">
        <div className="content-wrap">
          <Headers></Headers>
          <p></p>
          <div className="text-center">
            <span className="subTitleText">Select Delivery Company</span>

            <br />
            <br />

              <div className="container">
                {deliveryOptions.map(status => (
                  <div className="form-row justify-content-center">
                    <button
                      className={`flex w-full btn btn-secondary justify-between hover:bg-primary-300 mb-2 cursor-pointer ${this.state.courierCompany === status ? 'hover:bg-primary-500 bg-primary-400' : ''}`}
                      key={status}
                      onClick={(event) => this.handleButtonClick(status)}
                      style={{ width: '180px', height: '40px', margin: "0px 0px 20px 0px" }}
                    >
                      {status}
                      {status === this.state.courierCompany && <Icon icon="uil:check" />}
                    </button>
                  </div>
                ))}

                {this.state.showOther ? (
                  <div className="form-row justify-content-center">
                    <input
                      name="othername"
                      id="othername"
                      type="text"
                      placeholder="Courier Company Name *"
                      className="form-control+matching-text-style"
                      required
                      autoFocus
                      style={{ width: '180px' }}
                      value={this.state.courierOtherName}
                      onChange={this.onOtherChange}
                      autoComplete="off"
                    />
                    <br />
                    {/* <div style={{ display: this.state.errMsg_othername, color: 'red' }}><p>This is required, please complete.</p></div> */}
                    <span style={{ margin: '0px 0px 10px 0px', display: this.state.errMsg_othername, color: 'red' }}>This is required, please complete.</span>

                  </div>
                ) : (
                  <div>
                  </div>
                )}


                <div className="form-row justify-content-center">
                  <Button onClick={this.handleContinue} variant="secondary" style={{ margin: '20px 3px 5px 0px', width: '110px', fontSize: "14px" }} disabled={this.state.btnsDisabled}>Continue</Button>{' '}
                  <Button onClick={this.handleCancel} variant="danger" style={{ margin: '20px 3px 5px 0px', width: '110px', fontSize: "14px" }}>Cancel</Button>{' '}
                </div>

              </div>



              {/* <div className="form-row justify-content-center">
                <Table className="table-borderless" style={{ width: "310px" }}>
                  <tbody>
                    <tr>
                      <td></td>
                    </tr>



                  </tbody>
                </Table>
              </div> */}


            <Footer></Footer>

          </div>
        </div>
      </div>
    );
  }
}

export default deliveryPage;


