import React, { Component } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import ADMINICO from "../admin.png";
import PASSWICO from "../security.png";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import { Redirect } from 'react-router';
import axios from "axios";
import './loginFields.css';
import Cookies from 'universal-cookie';
import spacer from "../spacer.png";
import Show from "../show.png";
import Hide from "../hide.png";
import Modal from 'react-bootstrap/Modal';
import Headers from './header';
//import PasswordMask from 'react-password-mask-2';

const config = require('../config.json');
const cookies = new Cookies();

class Logins extends Component {
  _isMounted = false;

  constructor(props, context) {
    super(props, context);

    this.onClickPassword = this.onClickPassword.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false,
      showRoleError: false,
      // directTo: "/otpPref",
      directTo: "/otpFields",
      showVisitorDontExist: "none",
      showLogonError: "none",
      admins: [],
      username: "",
      email: "",
      password: "",
      mobile: "",
      country: "",
      region: "",
      buildings: "",
      buildingName: cookies.get('DVBuilding'),
      passwordType: "password",
      passwordIco: Show
    };
  }

  onClickPassword = e => {
    e.preventDefault();
    if (this.state.passwordType === "password") {
      this.setState({ passwordType: "text" });
      this.setState({ passwordIco: Hide });
    } else {
      this.setState({ passwordType: "password" });
      this.setState({ passwordIco: Show });
    }
  }

  handleClose() {
    this.setState({ showRoleError: false });
  }

  handleChange = e => {
    this.setState({ username: e.target.value })
    this.form.validateFields(e.target);
  }

  handleChangePassword = e => {
    this.setState({ password: e.target.value })
    this.form.validateFields(e.target);
  }

  adminSubmit = e => {
    e.preventDefault();

    this.form.validateFields();

    if (!this.form.isValid()) {
      // console.log('form is invalid: do not submit');
    } else {
      // console.log('form is valid: submit');
      this.fetchAdmins();
    }
  }

  fetchAdmins = async () => {

    try {
      const params = {
        "id": this.state.username.toLowerCase(),
        "password": this.state.password
      };
      
      const res = await axios.post(`${config.api.invokeUrl}/adminuserauth`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      this.setState({ admins: res.data }, function () {
      });
      
      if (this.state.admins.length == 0) {

        console.log("did not get admin" + this.state.admins.length);
        this.setState({ showVisitorDontExist: "block" });
        // this.setState({ showLogonError: "block" });
        
      } else {
        if (res.status == "208") {

          
          //Frans - Added local storage for session token
          localStorage.setItem('usersession', res.data.usersession);
          localStorage.setItem('userdisplayname', res.data.display_name);
          localStorage.setItem('email', res.data.email);

          this.setState({ showNotAllowed: "none" });
          this.setState({ showVisitorDontExist: "none" });


          try {
            const param = {
              //id: res.data.email.toLowerCase()
              id: localStorage.getItem('email'),
              usid: localStorage.getItem('usersession')
            };
            //const resAdmin = await axios.post(`${config.api.invokeUrl}/adminusers/` , param, {            
            const resAdmin = await axios.post(`${config.api.invokeUrl}/adminusers/`, param, {

              "headers": {
                "x-api-key": `${config.api.xapikey}`
              }
            });

            //console.log("adminuser api success");

            if (resAdmin.data.length === 0) {
              this.setState({ showUnauthorisedError: true });
            } else {

              //console.log(resAdmin.data[0]);
              const userRole = resAdmin.data[0].userrole;
              if (userRole === "Building Admin and Reception User") {

                //console.log("Building admin");

                this.setState({ showRoleError: false });

                this.setState({ id: resAdmin.data[0].email });
                this.setState({ mobile: resAdmin.data[0].mobile })
                this.setState({ username: resAdmin.data[0].email });
                this.setState({ email: resAdmin.data[0].email });
                this.setState({ country: resAdmin.data[0].country });
                this.setState({ region: resAdmin.data[0].buildingsregion });
                this.setState({ buildings: resAdmin.data[0].buildings });

                localStorage.setItem('buildings', resAdmin.data[0].buildings);
                localStorage.setItem('region', resAdmin.data[0].buildingsregion);
                localStorage.setItem('country', resAdmin.data[0].country);

                this.setState({ directTo: "/otpFields" }, function () {
                  this.setState({ directTo: "/otpFields" });
                  this.setState({ redirect: true });
                });

              } else {
                //console.log("Administrator! Should not be allowed to go on.");
                this.setState({ showRoleError: "block" });
              }
            }

          } catch (err) {
            console.log(`An error has occurred: ${err}`);
          }
        } else if (res.status == "201") {
          //console.log("Wrong username or password");
          this.setState({ showVisitorDontExist: "block" });
        }
      }

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  componentDidMount = () => {
    //let currentTimeInSeconds=Math.floor(Date.now()/1000); //unix timestamp in seconds
    //let currentTimeInMilliseconds=Date.now(); // unix timestamp in milliseconds    

    //const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
    //console.log(supportedConstraints);

    // alert(this.state.buildingName);
    if ((this.state.buildingName == '') || (this.state.buildingName == undefined)) {
      const cookies = new Cookies();
      cookies.set('DVBuilding', "", { path: '/', secure: true, sameSite: "lax" });
    } else {
      this.setState({ directTo: "/dvHost" }, function () {
        this.setState({ directTo: "/dvHost" });
        this.setState({ redirect: true });
      });
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.directTo,
        //search: "?utm=your+face",
        state: {
          id: this.state.username,
          username: this.state.username,
          mobile: this.state.mobile,
          email: this.state.email,
          country: this.state.country,
          region: this.state.region,
          buildings: this.state.buildings
        }
      }} />;
    }
    return (
      <div className="page-container">
        <div className="content-wrap">
          <Headers></Headers>
          <p></p>

          <div className="text-center">
            <p><span className="dvHeaderText">Reception Admin Login</span> </p>

            <br />

            <FormWithConstraints
              ref={form => this.form = form}
              onSubmit={this.adminSubmit}
              noValidate>

              <Container>
                <Row>
                  <Col></Col>
                  <Col xs={6}>
                    <div className="form-group">
                      <img src={ADMINICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                      <input
                        name="Username"
                        id="Username"
                        type="text"
                        placeholder="Username *"
                        className="form-control+matching-text-style"
                        required onChange={this.handleChange}
                        style={{ width: '75%' }}
                        autoComplete="off"
                      />
                      <FieldFeedbacks for="Username">
                        <FieldFeedback when="*" />
                      </FieldFeedbacks>
                    </div>

                  </Col>
                  <Col></Col>
                </Row>
                <Row>
                  <Col></Col>
                  <Col xs={6}>
                    <div className="form-group">
                      <img src={PASSWICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                      <input
                        name="Password"
                        id="Password"
                        type={this.state.passwordType}
                        placeholder="Password *"
                        className="form-control+matching-text-style"
                        required onChange={this.handleChangePassword}
                        style={{ width: '70%' }}
                      />
                      <img onClick={this.onClickPassword} src={this.state.passwordIco}></img>
                      <FieldFeedbacks for="Password">
                        <FieldFeedback when="*" />
                      </FieldFeedbacks>
                    </div>
                    <div style={{ display: this.state.showVisitorDontExist, color: 'red' }}><p>You have captured incorrect admin details. Please try again</p></div>
                    <div style={{ display: this.state.showLogonError, color: 'red' }}><p>Invalid details captured. Please try again or email VisitorsAppSupport@exponant.co.za</p></div>
                  </Col>
                  <Col></Col>
                </Row>
                <Row>
                  <Col></Col>
                  <Col xs={6}>
                    <div className="text-center">
                      {/* <Button variant="link" href="passwordRequest" size="sm">Forgot Password</Button>
                <p></p> */}
                      <button className="btn btn-secondary" style={{ width: '200px', margin: '0px 0px 90px 0px' }}>Login</button>
                    </div>
                  </Col>
                  <Col></Col>
                </Row>
              </Container>
            </FormWithConstraints>
          </div>


          <div className="main-footer">
            <div className="container">
              <img src={spacer}></img>
            </div>
            <div className="page-containerRight"></div>
          </div>

          <Modal show={this.state.showRoleError}
            onHide={this.handleClose}
            className="text-center"
            backdrop="static"
            keyboard={false}>
            <Modal.Body>
              <p style={{ color: "red" }}><b>Unassigned Building and Reception role</b></p>
              <span style={{ fontSize: "12px" }}>You do not have a building and reception user role.</span>
              <p></p>
              <Button style={{ width: "150px" }} variant="danger" onClick={this.handleClose}>
                Close
              </Button>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Logins;