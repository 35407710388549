import React, { Component } from 'react';
import axios from "axios";
import { Redirect } from 'react-router';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Cookies from 'universal-cookie';
import Visitor from './visitor';
import Modal from "react-bootstrap/Modal";
import Headers from './header';
import Footer from './footer';
import StarsRating from 'stars-rating'
import Table from "react-bootstrap/Table";
import { v4 as uuidv4 } from 'uuid';

const ratingChanged = (newRating) => {
  // console.log(newRating);
  ratingValue = newRating;
}

const config = require('../config.json');
const cookies = new Cookies();
let callCount = 1;
let ratingValue = "0";

class addVisitor extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showInfo: false,
      redirect: false,
      visitorsLoaded: false,
      searchState: '',
      surveyQuestion: '',
      surveyTitle: '',
      btnsDisabled: true,
      showRating: 'none',
      // directTo: "/visitorwelcome",
      // newvisitor: {
      //   "username": "",
      //   "id": ""
      // },
      currentMonth: new Date().getMonth() + 1,
      currentYear: new Date().getUTCFullYear(),
      hostCountry: cookies.get('DVCountry'),
      visitors: [],
      registervisitor: false,
      playHSVideo: cookies.get('DVmustwatchvideo'),
      showHSVideoInfo: false
    };

    this.handleAddVisitor = this.handleAddVisitor.bind(this);
    this.handleSendMessage = this.handleSendMessage.bind(this);
    this.handleOk = this.handleOk.bind(this);
  }

  componentDidMount = () => {
    this.setState({ searchState: "Loading..." });
    // console.log("Was at additional Visitor!");
    setTimeout(function () {
      this.fetchVisitor();

      // this.getSurvey();
    }.bind(this), 5000);

    // Retry loading visitors again if empty
    if (this.state.visitors.length === 0) {
      this.fetchVisitor();
    }

  }

  fetchVisitor = async () => {

    const visitKey = cookies.get('DVvisitkey');
    //console.log('visitKey: ' + visitKey);
    const params = {
      "newid": this.state.currentYear.toString() + (this.state.currentMonth).toString(),
      "visitkey": visitKey
    };

    try {
      const res = await axios.post(`${config.api.invokeUrl}/visitsvisitorquery`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      // console.log(res.data);

      if (res.data.length > 0) {
        this.setState({ searchState: "" });
        this.setState({ btnsDisabled: false });
        cookies.set('DVvisitVisitors', res.data, { path: '/', secure: true, sameSite: "lax" });
      }

      this.setState(
        {
          visitors: res.data,
          visitorsLoaded: true
        },
      );

      // cookies.set('DVvisitVisitors', res.data, { path: '/', secure: true, sameSite: "lax" });

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
      this.setState({ visitorsLoaded: true });
    }

    if (this.state.playHSVideo == 'yes') {
      this.setState({ showHSVideoInfo: true });
    }
    else {
      this.setState({ showHSVideoInfo: false });
      this.setState({ playHSVideo: 'no'});
    }

    this.setState({ btnsDisabled: false });
    // console.log("visitors length:" + this.state.visitors.length);
    if (this.state.visitors.length === 0) {
      this.setState({ searchState: "" });
      callCount = callCount + 1;
      if (callCount < 9) {
        // console.log(`Call fetchVisitor`);
        this.fetchVisitor();
      }

    }

    // cookies.set('DVvisitVisitors', this.state.visitors, { path: '/', secure: true, sameSite: "lax" });
    // console.log(this.state.visitors);

    // callCount = callCount + 1;
    // if (callCount < 9) {
    //   console.log(`Call fetchVisitor`);

    //   const visitKey = cookies.get('DVvisitkey');
    //   console.log(visitKey);
    //   try {
    //     const res = await axios.get(`${config.api.visitsVisitorURL}/visitsvisitor/` + visitKey, {
    //       "headers": {
    //         "x-api-key": `${config.api.xapikey}`
    //       }
    //     });

    //     console.log(res.data);
    //     cookies.set('DVvisitVisitors', res.data, { path: '/', secure: true, sameSite: "lax" });

    //     if (res.data.length > 0) {
    //       this.setState({ searchState: "" });
    //       this.setState({ btnsDisabled: false });
    //     }
    //     // else {
    //     //   this.setState({searchState : "No visitors associacted"}); 
    //     // }
    //     this.setState(
    //       {
    //         visitors: res.data,
    //         visitorsLoaded: true
    //       },
    //       this.fetchVisitor         // callback
    //     );

    //   } catch (err) {
    //     console.log(`An error has occurred: ${err}`);
    //     this.setState({ visitorsLoaded: true });
    //   }
    // } else {
    //   this.setState({ btnsDisabled: false });
    //   console.log("visitors length:" + this.state.visitors.length);
    //   if (this.state.visitors.length === 0) {
    //     this.setState({ searchState: "" });
    //   }
    // }
  }
  
  handleSendMessage() {

    this.sendEmailMessage();
    //todo: Enable this for sending SMS
    this.sendSMSMessage();

    callCount = 1;
    if (this.state.visitors.length > 0) {
      this.setState({ showInfo: true });
    } else {
      this.setState({ redirect: true });
      this.setState({ directTo: "/dvHost" });
    }
  }

  sendEmailMessage = async () => {
    const visitorsCount = this.state.visitors.length;
    const visitorsCurrent = this.state.visitors;
    const hostEmail = cookies.get('DVHostMail');
    const hostName = cookies.get('DVHostName');
    const building = cookies.get('DVBuilding');

    // console.log(visitorsCurrent);

    let visitorParty = " ";    
    this.state.visitors.map((visitor, index) => 
            visitorParty = visitorParty + ' ' + visitor.visitorname + ' ' + visitor.visitorlastname + ',');

    console.log(visitorParty);

 
    // let visitorParty = "";
    // console.log(visitorsCurrent);

    // if (visitorsCount > 0) {
    //   if (visitorsCount == 1) {
    //     visitorsCurrent.map((visitor, index) => {
    //       visitorParty = visitorParty + visitor.visitorname + ' ' + visitor.visitorlastname
    //     });
    //   }
    //   else if (visitorsCount > 1) {
    //     let lastVisitor = visitorsCurrent[visitorsCurrent.length - 1];
    //     let restOfVisitors = visitorsCurrent.pop();
    //     visitorsCurrent.map((visitor, index, visitorsCurrent) => {
    //       if (visitorsCurrent.length - 1 === index) {
    //         visitorParty = visitorParty + visitor.visitorname+' '+visitor.visitorlastname+'</b> and <b>'+lastVisitor.visitorname+' '+lastVisitor.visitorlastname
    //       } else {
    //         visitorParty = visitorParty + visitor.visitorname+' '+visitor.visitorlastname+', '
    //       }
    //     });
    //   }
    // }

    const emailBody = "<p>Dear " + hostName + ",</p><p>Please be advised that <b>" + visitorParty + "</b> has arrived at <b>" + building + "</b> Reception Area.</p><p>Kind Regards,<br/>Digital Visitors Team</p>";

    const params = {
      "toaddress": [hostEmail],
      "subject": "Your guest is here",
      "body": emailBody
    };
    //console.log(params);

    try {
      const res = await axios.post(`${config.api.invokeUrl}/email/`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      if (res.status === "200") {
        // this.setState({ redirect: true });
      } else {
        console.log(`An error has occurred trying to send email`);
      }

    } catch (err) {
      console.log(`An error has occurred trying to send email: ${err}`);
    }
  }

  sendSMSMessage = async () => {
    const visitorsCount = this.state.visitors.length;
    const visitorsCurrent = this.state.visitors;
    const hostMobile = cookies.get('DVHostMobile');
    const hostName = cookies.get('DVHostName');
    const building = cookies.get('DVBuilding');

    // no need to send sms if there is no number
    if (hostMobile === '') {
      return;
    }

    let visitorParty = "";

    if (visitorsCount > 0) {
      if (visitorsCount === 1) {
        this.state.visitors.map((visitor, index) =>
          visitorParty = visitorParty + visitor.visitorname + ' ' + visitor.visitorlastname
        );
      }
      else if (visitorsCount > 1) {
        let lastVisitor = visitorsCurrent[visitorsCurrent.length - 1];
        let restOfVisitors = visitorsCurrent.pop();
        visitorsCurrent.map((visitor, index, visitorsCurrent) => {
          if (visitorsCurrent.length - 1 === index) {
            visitorParty = visitorParty + visitor.visitorname+' '+visitor.visitorlastname+' and '+lastVisitor.visitorname+' '+lastVisitor.visitorlastname
          } else {
            visitorParty = visitorParty + visitor.visitorname+' '+visitor.visitorlastname+', '
          }
        });
      }
    }
    
    if (visitorParty === '') {
      return;
    }

    const smsBody = "Dear " + hostName + ", Please be advised that " + visitorParty + " has arrived at " + building + " Reception Area.";

    const params = {
      "tomobile": hostMobile,
      "body": smsBody
    };

    // console.log(params);

    try {
      const res = await axios.post(`${config.api.invokeUrl}/sms/`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`,
          'Content-Type': 'application/json'
        }
      });

      if (res.status === "201") {
        // this.setState({ redirect: true });
      } else {
        console.log(`An error has occurred trying to send sms`);
      }

    } catch (err) {
      console.log(`An error has occurred trying to send sms: ${err}`);
    }

  }

  handleAddVisitor() {
    callCount = 1;
    this.setState({ redirect: true });
    this.setState({ directTo: "/visitorwelcome" });
  }

  handleOk() {
    
    // this.handleAddSurveyResult();

    cookies.set("DVHostPhoto", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVHostName", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVHostMail", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVHostMobile", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitkey", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitdate", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitreason", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitorType", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitVisitors", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitDuration", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVmustwatchvideo", '', { path: '/', secure: true, sameSite: "lax" });

    //console.log(this.state.playHSVideo);
    // return;


    if (this.state.playHSVideo == 'yes') {
      this.setState({ registervisitor: true });
      this.setState({ directTo: "/inductionVideo" });
    }
    else {
      this.setState({ directTo: "/dvHost" });
    }

    this.setState({ redirect: true });

  }

  handleAddSurveyResult = async () => {
    if (this.state.showRating === "block") {
      const curTime = new Date().toLocaleString();
      const guid = uuidv4();
      const id = guid;
      let firstname = "";
      let lastname = "";
      let mobile = "";
      let email = "";
      let idno = "";

      // console.log(cookies.get("DVvisitorType"));
      if (cookies.get("DVvisitorType") === 'Unregistered') {
        firstname = "Anonymous";
        lastname = "Anonymous";
        mobile = "Anonymous";
        email = "Anonymous";
        idno = "Anonymous";
      } else {
        firstname = this.props.location.state.firstname;
        lastname = this.props.location.state.lastname;
        mobile = this.props.location.state.mobile;
        email = this.props.location.state.email;
        idno = this.props.location.state.id;
      }

      // add call to AWS API Gateway add visitor endpoint here
      let currentTimeInMilliseconds = " ";
      currentTimeInMilliseconds = Date.now().toString(); // unix timestamp in milliseconds 
      try {
        const params = {
          "newid": this.state.currentYear.toString() + this.state.currentMonth.toString(),
          "timestamps": currentTimeInMilliseconds,
          "id": guid,
          "firstname": firstname,
          "lastname": lastname,
          "mobile": mobile,
          "email": email,
          "idno": idno,
          "surveyquestion": this.state.surveyQuestion,
          "surveyresult": "" + ratingValue + "",
          "recorddate": curTime,
          "surveytitle": this.state.surveyTitle,
          "country": this.state.hostCountry,
          "username": localStorage.getItem('email'),
          "usid": localStorage.getItem('usersession')
        };

        // console.log(params);
        await axios.post(`${config.api.invokeUrl}/surveyresults/{id}`, params, {
          "headers": {
            "x-api-key": `${config.api.xapikey}`
          }
        });

      } catch (err) {
        console.log(`An error has occurred: ${err}`);
      }
    }
  }



  getSurvey = async () => {
    try {
      const res = await axios.get(`${config.api.invokeUrl}/surveymasters/` + this.state.hostCountry, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      if (res.data[0].statussurvey === "Active") {
        this.setState({ showRating: "block" });
        this.setState({ surveyTitle: res.data[0].title });
        this.setState({ surveyQuestion: res.data[0].question });
      } else {
        this.setState({ surveyTitle: "" });
        this.setState({ surveyQuestion: "" });
        this.setState({ showRating: "none" });
      }
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.directTo,
        state: {
          hostName: cookies.get('DVHostName'),
          hostMobile: cookies.get('DVHostMobile'),
          hostEmail: cookies.get('DVHostMail'),
          hostPhoto: cookies.get('DVHostPhoto'),
          registervisitor: this.state.registervisitor,
          allvisitors: this.state.visitors
        }
      }} />;
    }
    return (
      <div className="page-container">
        <div className="content-wrap">
          <Headers></Headers>
          <p></p>

          <div className="text-center">

            <h3 className="mainTitleText">Would you like to add a visitor to your group visiting Pernod Ricard's premises?</h3>

            <br></br>

            <Container>
              <Row>
                <Col className="text-right"></Col>
                <Col xs={6}>

                  <Button onClick={this.handleAddVisitor} variant="secondary">Yes</Button>{' '}
                  <Button onClick={this.handleSendMessage} variant="danger" disabled={this.state.btnsDisabled}>No</Button>{' '}

                </Col>
                <Col></Col>
              </Row>
              <Row>
                <Col className="text-right"></Col>
                <Col xs={6}>
                  <br />
                  <p>Visitors:</p>
                  <div className="tile is-child box notification is-success">
                  </div>
                  <div className="form-group">
                    <span style={{ color: "red" }}>{this.state.searchState}</span>
                  </div>
                  {
                    this.state.visitors.map((visitor, index) =>
                      <Visitor
                        key={visitor.id}
                        visitorname={visitor.visitorname}
                        visitorlastname={visitor.visitorlastname}
                      />)
                  }
                </Col>
                <Col></Col>
              </Row>
            </Container>

            <Modal
              show={this.state.showInfo}
              onHide={this.handleClose}
              className="text-center"
              backdrop="static"
              keyboard={false}>

              <Modal.Body>
                <br />
                <br />
                <p>
                  <span className="subTitleText">Your host has been notified of your arrival, please remain at reception.</span>
                  <br />
                  {/* <br /> */}
                  <br />
                  {/* <div style={{ display: this.state.showHSVideoInfo }}>
                    <span className="subTitleText">The health and safety video will start now...</span>
                  </div> */}
                </p>
                <Table className="table-borderless" style={{ width: "100%", textAlign: "center" }}>
                  <tbody>
                    <tr>
                      <td colSpan="3">
                        {this.state.surveyQuestion}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25%", textAlign: "center" }}></td>
                      <td style={{ width: "50%", textAlign: "center" }}>
                        <div style={{ display: this.state.showRating }}>
                          <StarsRating
                            count={10}
                            onChange={ratingChanged}
                            size={24}
                            color2={'#ffd700'} />
                        </div>
                      </td>
                      <td style={{ width: "25%", textAlign: "center" }}></td>
                    </tr>
                  </tbody>
                </Table>
              </Modal.Body>
              <Modal.Footer >
                <Button variant="secondary" onClick={this.handleOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>

            <Footer></Footer>
          </div>
        </div>
      </div>
    )
  }
}

export default addVisitor;