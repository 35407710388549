import React, { Component } from 'react';
import QrReader from 'react-qr-reader';
import axios from "axios";
import { Redirect } from 'react-router';
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid'
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Footer from './footer';

const config = require('../config.json');
const cookies = new Cookies();
let scanned = false;

class scanQR extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleClose = this.handleClose.bind(this);

    this.state = {
      result: 'No result',
      redirect: false,
      directTo: "/visitorwelcome",
      visitor: [],
      addVisitor: true,
      newVisitor: {
        "id": "",
        "firstname": "",
        "lastname": "",
        "company": "",
        "email": "",
        "laptop": "",
        "laptopserial": "",
        "mobile": "",
        "vehicle": "",
        "image": ""
      },
      currentMonth: new Date().getMonth() + 1,
      currentYear: new Date().getUTCFullYear()
    };
  }

  fetchVisitor = async () => {
    try {
      // console.log(this.state.result);
      const res = await axios.get(`${config.api.invokeUrl}/visitorqr/` + this.state.result, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      this.setState({ visitor: res.data }, function () {
      });

      // console.log(this.state.visitor);

      if (this.state.visitor.length == 0) {
        alert("Invalid QR code.");
        this.setState({ directTo: "/visitorwelcome" });
        this.setState({ redirect: true });
      } else {
        this.setState({ newVisitor: { ...this.state.newVisitor, "id": res.data[0].id } })
        this.setState({ newVisitor: { ...this.state.newVisitor, "firstname": res.data[0].firstname } })
        this.setState({ newVisitor: { ...this.state.newVisitor, "lastname": res.data[0].lastname } })
        this.setState({ newVisitor: { ...this.state.newVisitor, "company": res.data[0].company } })
        this.setState({ newVisitor: { ...this.state.newVisitor, "mobile": res.data[0].mobile } })
        this.setState({ newVisitor: { ...this.state.newVisitor, "image": res.data[0].image } })
        this.setState({ newVisitor: { ...this.state.newVisitor, "email": res.data[0].email } })

        cookies.set("DVvisitorType", 'Registered', { path: '/', secure: true, sameSite: "lax" });

        //console.log(this.state.newVisitor);

        // Frans - Added the save - 2022-07-15
        this.handleSaveVisitor();

        // // this.setState({directTo: "/additionalQuestions"});
        // this.setState({directTo: "/additionalVisitor"});
        // this.setState({redirect: true});
      }

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  handleSaveVisitor() {
    const guid = uuidv4();
    this.handleAddVisitsVisitor(
      this.state.newVisitor.id,
      this.state.newVisitor.firstname,
      this.state.newVisitor.lastname,
      guid,
      this.state.newVisitor.email,
      this.state.newVisitor.company,
      this.state.newVisitor.mobile,
      this.state.newVisitor.image
    );

    this.setState({ directTo: "/additionalVisitor" });
    this.setState({ redirect: true });
  }

  handleAddVisitsVisitor = async (id, firstname, lastname, guid, email, company, mobile, image) => {
    const curTime = new Date().toLocaleString();

    let currentTimeInMilliseconds = " ";
    currentTimeInMilliseconds = Date.now().toString(); // unix timestamp in milliseconds 

    // add call to AWS API Gateway add visitor endpoint here
    try {
      // const params = {
      //     "id": guid,
      //     "visitkey": cookies.get('DVvisitkey'),
      //     "visitorid": id,
      //     "visitorname": firstname,
      //     "visitorlastname": lastname,
      //     "username": localStorage.getItem('username'),
      //     "usid": localStorage.getItem('usersession')
      // };

      const params = {
        "newid": this.state.currentYear.toString() + this.state.currentMonth.toString(),
        "timestamps": currentTimeInMilliseconds,
        "id": guid,
        "visitkey": cookies.get('DVvisitkey'),
        "visitorid": id,
        "visitorname": firstname,
        "visitorlastname": lastname,
        "visitormobile": mobile,
        "visitoremail": email,
        "visitorcompany": company,
        "visitortype": cookies.get("DVvisitorType"),
        "country": cookies.get("DVCountry"),
        "building": cookies.get("DVBuilding"),
        "visitdate": cookies.get("DVvisitdate"),
        "hostname": cookies.get("DVHostName"),
        "visitreason": cookies.get("DVvisitreason"),
        "visitorimage": image,
        "visittime": cookies.get("DVvisitDuration"),
        username: localStorage.getItem('email'),
        usid: localStorage.getItem('usersession')
      };

      console.log(params);
      //return;

      await axios.post(`${config.api.invokeUrl}/visitsvisitor/{id}`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  handleClose() {
    this.setState({ directTo: "/visitorwelcome" });
    this.setState({ redirect: true });
  }

  handleScan = data => {
    // console.log('handleScan');
    if (data) {
      if (scanned === false) {
        scanned = true;
        // console.log(data);
        this.setState({ result: data })
        this.fetchVisitor();
      }
    }
  }
  handleError = err => {
    console.error(err)
  }
  render() {
    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.directTo,
        state: {
          // hostName: cookies.get('DVHostName'),
          // hostMobile: cookies.get('DVHostMobile'),
          // hostEmail: cookies.get('DVHostMail'),
          // hostPhoto: cookies.get('DVHostPhoto')
          id: this.state.newVisitor.id,
          email: this.state.newVisitor.email,
          firstname: this.state.newVisitor.firstname,
          lastname: this.state.newVisitor.lastname,
          company: this.state.newVisitor.company,
          mobile: this.state.newVisitor.mobile,
          image: this.state.newVisitor.image
        }
      }} />;
    }
    return (
      <div className="text-center" style={{width: "100%"}}>
        <span className="subTitleText">Present the QR code to be scanned.</span>
        <Table className="table-borderless" style={{ width: "100%", textAlign: "center" }}>
          <tbody>
            <tr>
              <td style={{ width: "33%", textAlign: "center" }}></td>
              <td style={{ width: "33%", textAlign: "center" }}>
                <QrReader
                  delay={300}
                  onError={this.handleError}
                  onScan={this.handleScan}
                  style={{ width: '400px', height: '400px' }}
                  facingMode={'user'}
                />
              </td>
              <td style={{ width: "33%", textAlign: "center" }}></td>
            </tr>
            <tr>
              <td colSpan="3">
                <Button variant="danger" onClick={this.handleClose} style={{ width: "200px", margin: "0px 0px 40px 0px" }}>
                  Cancel
                </Button>
                <br />
                <p>{this.state.result}</p>
              </td>
            </tr>
          </tbody>
        </Table>

        
        <Footer></Footer>
      </div>
    )
  }
}

export default scanQR;