import React, { Component } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Jumbotron from "../admin.png"
import Jumbotron1 from "../security.png"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PASSWICO from "../security.png";
import Button from 'react-bootstrap/Button';
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import axios from "axios";
import { Redirect } from "react-router";
import Modal from "react-bootstrap/Modal";
import spacer from "../spacer.png";
import Show from "../show.png";
import Hide from "../hide.png";
import Headers from "./header";

const config = require('../config.json');

class PasswordReset extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleClose = this.handleClose.bind(this);
    this.onClickPassword = this.onClickPassword.bind(this);

    this.state = {
      redirect: false,
      id: this.props.location.state.id,
      password: "",
      passwordConfirm: "",
      mobile: this.props.location.state.mobile,
      email: this.props.location.state.email,
      prevpass1: "",
      prevpass2: "",
      prevpass3: "",
      country: this.props.location.state.country,
      buildingsregion: this.props.location.state.buildingsregion,
      userrole: this.props.location.state.userrole,
      buildings: this.props.location.state.buildings,
      showMessage: false,
      errorMessage: "",
      errorMessage1: "",
      errorMessage2: "",
      errorMessage3: "",
      errorMessage4: "",
      errorMessage5: "",
      passwordType: "password",
      passwordIco: Show
    };
  }

  onClickPassword = e => {
    e.preventDefault();
    if (this.state.passwordType === "password") {
      this.setState({ passwordType: "text" });
      this.setState({ passwordIco: Hide });
    } else {
      this.setState({ passwordType: "password" });
      this.setState({ passwordIco: Show });
    }
  }

  handleClose() {
    this.setState({ showMessage: false });
  }

  onUpdateAdminPasswordChange = event => this.setState({ password: event.target.value });
  onUpdateAdminPasswordConfirmChange = event => this.setState({ passwordConfirm: event.target.value });

  handleSubmit = e => {
    e.preventDefault();
    // console.log(this.state.id);
    this.form.validateFields();



    if (this.form.isValid()) {
      let validationPass = true;
      const newPassword = this.state.password;

      this.setState({ errorMessage: `` });
      this.setState({ errorMessage1: `` });
      this.setState({ errorMessage2: `` });
      this.setState({ errorMessage3: `` });
      this.setState({ errorMessage4: `` });
      this.setState({ errorMessage5: `` });

      if (newPassword !== "") {
        if (newPassword == this.state.passwordConfirm) {
          //console.log("Passwords match");      
        } else {
          validationPass = false;
          this.setState({ showMessage: true });
          this.setState({ errorMessage: ` Passwords do not match.` });
        }
      }

      //Atleast one capital letter
      let test = /[A-Z]+/;
      if (newPassword.match(test)) {
        // alert('correct one capital');
      } else {
        validationPass = false;
        this.setState({ showMessage: true });
        this.setState({ errorMessage1: ` Password should contain one upper case character.` });
      }


      //Digit Count 
      var count = 0;
      for (var i = 0, len = newPassword.length; i < len; i++) {
        if (this.isAsciiCodeDigit(newPassword.charCodeAt(i))) {
          count++;
        }
      }
      if (count > 1) {
        //alert('correct atleast 2 digits');
      } else {
        validationPass = false;
        this.setState({ showMessage: true });
        this.setState({ errorMessage2: ` Password should contain at least 2 digits.` });
      }

      //Alphabetic characters atleast 4 
      var countAlph = 0;
      for (var i = 0, len = newPassword.length; i < len; i++) {
        if (this.isAsciiCodeLetter(newPassword.charCodeAt(i))) {
          countAlph++;
        }
      }
      if (countAlph > 3) {
        // alert('correct atleast 4 alphabetical');
      } else {
        validationPass = false;
        this.setState({ showMessage: true });
        this.setState({ errorMessage3: ` Password should contain at least 4 alphabetical characters.` });
      }


      //Special characters atleast 2 
      var countSpec = 0;
      for (var i = 0, len = newPassword.length; i < len; i++) {
        if (this.isAsciiCodeSpecial(newPassword.charCodeAt(i))) {
          countSpec++;
        }
      }
      if (countSpec > 1) {
        // alert('correct atleast 2 special');
      } else {
        validationPass = false;
        this.setState({ showMessage: true });
        this.setState({ errorMessage4: ` Password should contain at least 2 special characters.` });
      }


      // console.log(this.state.prevpass1); 
      // console.log(this.state.prevpass2); 
      // console.log(this.state.prevpass3); 

      if ((newPassword == this.state.prevpass1) ||
        (newPassword == this.state.prevpass2) ||
        (newPassword == this.state.prevpass3)) {
        //Password matches a previous used password;      
        validationPass = false;
        this.setState({ showMessage: true });
        this.setState({ errorMessage5: ` Your password can not be the same as your last 3 passwords.` });
      }





      if (validationPass == true) {
        this.handleUpdateVisitor();
      }

    }


  };

  isAsciiCodeDigit(asciiCode) {
    return 48 <= asciiCode && asciiCode <= 57;
  }

  isAsciiCodeLetter(asciiCode) {
    return (65 <= asciiCode && asciiCode <= 90) ||
      (97 <= asciiCode && asciiCode <= 122);
  }

  isAsciiCodeSpecial(asciiCode) {
    return (32 <= asciiCode && asciiCode <= 47) ||
      (58 <= asciiCode && asciiCode <= 64) ||
      (91 <= asciiCode && asciiCode <= 96) ||
      (123 <= asciiCode && asciiCode <= 126);
  }

  handleUpdateVisitor = async () => {
    try {
      const params = {
        "id": this.state.id,
        "password": this.state.password
      };

      await axios.patch(`${config.api.invokeUrl}/adminuserpwd/`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      this.setState({ directTo: "/" }, function () {

      });
      this.setState({ redirect: true }, function () {

      });
      //alert("Password was successfully reset.");
      this.setState({ redirect: true });
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  getAdminUser = async () => {
    // console.log('getAdminUser'); 
    const params = {
      "id": this.state.id
    };
    try {
      const res = await axios.post(`${config.api.invokeUrl}/adminuserpwd/`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      // console.log(res.data); 
      this.setState({ prevpass1: res.data[0].prevpass1 });
      this.setState({ prevpass2: res.data[0].prevpass2 });
      this.setState({ prevpass3: res.data[0].prevpass3 });
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  componentDidMount() {
    // console.log(this.state.mobile);
    // console.log(this.state.email);
    this.getAdminUser();
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: "/"
      }} />;
    }
    return (

      <div className="page-container">
        <div className="content-wrap">
          <Headers></Headers>
          <p></p>
          <div class="text-center">
            <p><span className="dvHeaderText" style={{ fontSize: '20px' }}>Reset password for {this.state.mobile} {this.state.email}</span></p>
            <br />
            <FormWithConstraints
              ref={form => this.form = form}
              onSubmit={this.handleSubmit}
              noValidate>

              <Container>
                <Row>
                  <Col></Col>
                  <Col xs={6}>

                    <div className="form-group">
                      <img src={PASSWICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                      <input
                        name="password"
                        id="password"
                        type={this.state.passwordType}
                        placeholder="New Password"
                        className="form-control+matching-text-style"
                        required onChange={this.onUpdateAdminPasswordChange}
                        style={{ width: '85%' }}
                      />
                      <img onClick={this.onClickPassword} src={this.state.passwordIco}></img>
                      <FieldFeedbacks for="password">
                        <FieldFeedback when="*" />
                      </FieldFeedbacks>
                    </div>


                    <div className="form-group">
                      <img src={PASSWICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                      <input
                        name="passwordConfirm"
                        id="passwordConfirm"
                        type={this.state.passwordType}
                        placeholder="Confirm Password"
                        className="form-control+matching-text-style"
                        required onChange={this.onUpdateAdminPasswordConfirmChange}
                        style={{ width: '85%' }}
                      />
                      <img onClick={this.onClickPassword} src={this.state.passwordIco}></img>
                      <FieldFeedbacks for="passwordConfirm">
                        <FieldFeedback when="*" />
                      </FieldFeedbacks>
                    </div>



                    <p />

                  </Col>
                  <Col></Col>
                </Row>
                <Row>
                  <Col></Col>
                  <Col xs={6}>
                    <p></p>
                    <button className="btn btn-secondary" style={{ width: '200px', margin: '0px 0px 30px 0px' }}>Save</button>
                  </Col>
                  <Col></Col>
                </Row>
                <Row>
                  <Col></Col>
                  <Col xs={6}>
                    <p style={{ margin: '0px 0px 90px 0px' }}>
                      <span>Your new password can not be the same as your last 3 passwords.​</span><br />
                      <span>Your new password must contain:​</span><br />
                      <span>4 alphabetical characters, 1 Upper case alphabetic character,</span><br />
                      <span>2 numeric character, 2 special characters</span>
                    </p>
                  </Col>
                  <Col></Col>
                </Row>
              </Container>

            </FormWithConstraints>



            <Modal
              dialogClassName="my-modal"
              show={this.state.showMessage}
              onHide={this.handleClose}
              backdrop="static"
              keyboard={false}>


              <Modal.Body>

                <p>

                  <span className="errorMessageTitle">Error Message</span>
                </p>


                <p>
                  <span>{this.state.errorMessage}</span>
                  <span>{this.state.errorMessage1}</span>
                  <span>{this.state.errorMessage2}</span>
                  <span>{this.state.errorMessage3}</span>
                  <span>{this.state.errorMessage4}</span>
                  <span>{this.state.errorMessage5}</span><br />
                </p>



                <Button variant="danger" onClick={this.handleClose} style={{ width: '150px' }}>
                  Close
                </Button>

              </Modal.Body>

            </Modal>

            <div className="main-footer">
              <div className="container">
                <img src={spacer}></img>
              </div>
              <div className="page-containerRight"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordReset;