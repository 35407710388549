import React, { Component } from 'react';
import axios from "axios";
import { Redirect } from 'react-router';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Cookies from 'universal-cookie';
import Visitor from './visitor';
import Modal from "react-bootstrap/Modal";
import CovidNotice from "../Covid.PNG";
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import Select from "react-select";
import { v4 as uuidv4 } from 'uuid';
import ADMINICO from "../admin.png";
import Headers from './header';
import Footer from './footer';

const config = require('../config.json');
const cookies = new Cookies();

const options = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

class additionalQ extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleRemoveVisitor = this.handleRemoveVisitor.bind(this);
    this.handleSaveVisitor = this.handleSaveVisitor.bind(this);
    this.handleChangeTemp = this.handleChangeTemp.bind(this);

    this.state = {
      radiosDone: 0,
      redirect: false,
      showQuestion: "block",
      showTravelled: "none",
      showQuarantined: "none",
      showSymptoms: "none",
      showMessage: false,
      directTo: "/visitorwelcome",
      id: this.props.location.state.id,
      firstname: this.props.location.state.firstname,
      lastname: this.props.location.state.lastname,
      email: this.props.location.state.email,
      company: this.props.location.state.company,
      mobile: this.props.location.state.mobile,
      image: this.props.location.state.image,
      cough: "",
      fever: "",
      breath: "",
      smell: "",
      throat: "",
      pain: "",
      chills: "",
      Nausea: "",
      vomit: "",
      diarrhea: "",
      temp: "",
      terms: false,
      showTerms: "none",
      showTemp: "none",
      currentMonth: new Date().getMonth() + 1,
      currentYear: new Date().getUTCFullYear()
    };
  }

  handleRemoveVisitor() {
    // Cancel the visit
    this.setState({ firstname: "" });
    this.setState({ lastname: "" });
    cookies.set("DVHostPhoto", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVHostName", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVHostMail", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVHostMobile", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitkey", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitdate", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitreason", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitorType", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitVisitors", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitDuration", '', { path: '/', secure: true, sameSite: "lax" });

    this.setState({ directTo: "/dvHost" });
    this.setState({ redirect: true });
  }

  handleSaveVisitor() {
    const guid = uuidv4();
    this.handleAddVisitsVisitor(
      this.state.id,
      this.state.firstname,
      this.state.lastname,
      guid,
      this.state.email,
      this.state.company,
      this.state.mobile,
      this.state.image);

    this.setState({ directTo: "/additionalVisitor" });
    this.setState({ redirect: true });
  }

  // handleAddVisitsVisitor = async (id,name,lastname,guid) => {
  //   const curTime = new Date().toLocaleString();

  //   // add call to AWS API Gateway add visitor endpoint here
  //   try {
  //       const params = {
  //           "id": guid,
  //           "visitkey": cookies.get('DVvisitkey'),
  //           "visitorid": id,
  //           "visitorname": name,
  //           "visitorlastname": lastname
  //       };

  //       await axios.post(`${config.api.visitsVisitorURL}/visitsvisitor/{id}`, params);        

  //   }catch (err){
  //       console.log(`An error has occurred: ${err}`);
  //   }    
  // }

  handleAddVisitsVisitor = async (id, name, lastname, guid, email, company, mobile, image) => {
    const curTime = new Date().toLocaleString();

    let currentTimeInMilliseconds = " ";
    currentTimeInMilliseconds = Date.now().toString(); // unix timestamp in milliseconds 

    try {
      const params = {
        "newid": this.state.currentYear.toString() + this.state.currentMonth.toString(),
        "timestamps": currentTimeInMilliseconds,
        "id": guid,
        "visitkey": cookies.get('DVvisitkey'),
        "visitorid": id,
        "visitorname": name,
        "visitorlastname": lastname,
        "visitormobile": mobile,
        "visitoremail": email,
        "visitorcompany": company,
        "visitortype": cookies.get("DVvisitorType"),
        "country": cookies.get("DVCountry"),
        "building": cookies.get("DVBuilding"),
        "visitdate": cookies.get("DVvisitdate"),
        "hostname": cookies.get("DVHostName"),
        "visitreason": cookies.get("DVvisitreason"),
        "visitorimage": image,
        "visittime": cookies.get("DVvisitDuration"),
        username: localStorage.getItem('email'),
        usid: localStorage.getItem('usersession')
      };

      await axios.post(`${config.api.invokeUrl}/visitsvisitor/{id}`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  handleDeleteVisitsVisitor = async () => {
    const curTime = new Date().toLocaleString();
    // add call to AWS API Gateway add visitor endpoint here
    try {
      await axios.delete(`${config.api.invokeUrl}/visitsvisitor/` + this.state.visitorvisitkey, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });
    } catch (err) {
      console.log(`Could not delete visit event: ${err}`);
    }
  }

  setTravel(event) {
    if (event.target.value === 'Yes') {
      this.setState({ showTravelled: "block" });
    }
    if (event.target.value === 'No') {
      this.setState({ showTravelled: "none" });
      this.setState({ showQuarantined: "block" });
    }
  }

  setQuarantined(event) {
    if (event.target.value === 'Yes') {
      this.setState({ showQuarantined: "block" });
    }
    if (event.target.value === 'No') {
      this.setState({ showQuarantined: "none" });
      this.setState({ showMessage: true });
    }
  }

  setContacted(event) {
    if (event.target.value === 'Yes') {
      this.setState({ showMessage: true });
    }
    if (event.target.value === 'No') {
      this.setState({ showMessage: false });
      this.setState({ showSymptoms: true });
      this.setState({ showQuestion: "none" });
      this.setState({ showQuarantined: "none" });
      this.setState({ showTravelled: "none" });
    }
  }

  setCough(event) {
    if (this.state.cough === "") {
      this.setState({ radiosDone: this.state.radiosDone + 1 })
    }
    this.setState({ cough: event.target.value });
  }

  setFever(event) {
    if (this.state.fever === "") {
      this.setState({ radiosDone: this.state.radiosDone + 1 })
    }
    this.setState({ fever: event.target.value });
  }

  setBreath(event) {
    if (this.state.breath === "") {
      this.setState({ radiosDone: this.state.radiosDone + 1 })
    }
    this.setState({ breath: event.target.value });
  }

  setSmell(event) {
    if (this.state.smell === "") {
      this.setState({ radiosDone: this.state.radiosDone + 1 })
    }
    this.setState({ smell: event.target.value });
  }

  setThroat(event) {
    if (this.state.throat === "") {
      this.setState({ radiosDone: this.state.radiosDone + 1 })
    }
    this.setState({ throat: event.target.value });
  }

  setPain(event) {
    if (this.state.pain === "") {
      this.setState({ radiosDone: this.state.radiosDone + 1 })
    }
    this.setState({ pain: event.target.value });
  }

  setChills(event) {
    if (this.state.chills === "") {
      this.setState({ radiosDone: this.state.radiosDone + 1 })
    }
    this.setState({ chills: event.target.value });
  }

  setNausea(event) {
    if (this.state.Nausea === "") {
      this.setState({ radiosDone: this.state.radiosDone + 1 })
    }
    this.setState({ Nausea: event.target.value });
  }

  setVomit(event) {
    if (this.state.vomit === "") {
      this.setState({ radiosDone: this.state.radiosDone + 1 })
    }
    this.setState({ vomit: event.target.value });
  }

  setDiarrhea(event) {
    if (this.state.diarrhea === "") {
      this.setState({ radiosDone: this.state.radiosDone + 1 })
    }
    this.setState({ diarrhea: event.target.value });
  }

  handleChange(e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (value === true) {
      this.setState({ showTerms: "none" });
      this.setState({ terms: true });
    } else {
      this.setState({ terms: false });
    }
  }

  handleChangeTemp = event => {
    const target = event.target.value;
    let regexp = /^\d+\.?\d{0,2}$/;
    // alert(regexp.test(target));

    if (regexp.test(target)) {
      this.setState({ showTemp: "none" });
      this.setState({ temp: target });
    } else {
      this.setState({ showTemp: "block" });
      event.target.value = "";
    }
  }

  nextClick = e => {
    e.preventDefault();
    let formIsValid = true;
    //alert(this.state.temp > 37);

    this.form.validateFields();

    // Temperature test
    let regexp = /^\d+\.?\d{0,2}$/;
    if (regexp.test(this.state.temp)) {
      this.setState({ showTemp: "none" });
    } else {
      this.setState({ showTemp: "block" });
      formIsValid = false;
    }

    if (!this.form.isValid()) {
      alert("Please indicate that you accept the Terms and Conditions");
      formIsValid = false;
    } else {
      //console.log('form is valid: submit');
      if ((this.state.cough === "Yes") ||
        (this.state.fever === "Yes") ||
        (this.state.breath === "Yes") ||
        (this.state.smell === "Yes") ||
        (this.state.throat === "Yes") ||
        (this.state.pain === "Yes") ||
        (this.state.chills === "Yes") ||
        (this.state.Nausea === "Yes") ||
        (this.state.vomit === "Yes") ||
        (this.state.diarrhea === "Yes") ||
        (this.state.temp > 37.7)) {

        this.setState({ showMessage: true });
        formIsValid = false;
      } else {
        if ((this.state.terms === true)) {
          if (formIsValid === true) {
            this.handleSaveVisitor();
          }
        } else {
          this.setState({ showTerms: "block" });
        }
      }
    }
  }

  render() {
    const {
      radiosDone,
    } = this.state;

    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.directTo,
        state: {
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          id: this.state.id,
          email: this.state.email,
          mobile: this.state.mobile,
        }
      }} />;
    }
    return (

      <div className="page-container">
        <div className="content-wrap">
          <Headers></Headers>
          <p></p>

          <div className="text-center">

            <br></br>

            <Container>
              <Row>
                <Col></Col>
                <Col xs={8}>
                  <div>
                    <div onChange={this.setTravel.bind(this)} style={{ margin: '0px 0px 20px 0px', display: this.state.showQuestion }}>
                      <p><span style={{ fontWeight: "bold" }}>Have you travelled in the last 14 days?</span></p>
                      <input type="radio" value="Yes" name="travelled" style={{ margin: '0px 3px 0px 0px' }} /><span style={{ margin: '0px 10px 0px 0px' }}>Yes</span>
                      <input type="radio" value="No" name="travelled" style={{ margin: '0px 3px 0px 0px' }} />No
                    </div>
                  </div>

                  <div style={{ display: this.state.showTravelled }}>
                    <p><span>Please indicate the country travelled from?</span></p>
                    <input
                      name="country"
                      id="country"
                      type="text"
                      placeholder="Please indicate the country travelled from"
                      className="form-control+matching-text-style"
                      style={{ width: '85%' }}
                    />
                    <div onChange={this.setQuarantined.bind(this)} style={{ margin: '0px 0px 20px 0px' }}>
                      <p><span>Have you quarantined for the mandatory 10 day period?</span></p>
                      <input type="radio" value="Yes" name="quarantined" style={{ margin: '0px 3px 0px 0px' }} /><span style={{ margin: '0px 10px 0px 0px' }}>Yes</span>
                      <input type="radio" value="No" name="quarantined" style={{ margin: '0px 3px 0px 0px' }} />No
                    </div>
                  </div>

                  <div onChange={this.setContacted.bind(this)} style={{ display: this.state.showQuarantined }}>
                    <p><span>Have you been in contact with any person who has tested positive for COVID-19?</span></p>
                    <input type="radio" value="Yes" name="contacted" style={{ margin: '0px 3px 0px 0px' }} /><span style={{ margin: '0px 10px 0px 0px' }}>Yes</span>
                    <input type="radio" value="No" name="contacted" style={{ margin: '0px 3px 0px 0px' }} />No
                  </div>

                  <div style={{ display: this.state.showSymptoms }}>
                    <FormWithConstraints
                      ref={form => this.form = form}
                      onSubmit={this.nextClick}
                      noValidate>

                      <div className="container" >
                        <p><span style={{ fontWeight: "bold" }}>COVID-19 PRIMARY SYMPTOM SCREENING</span></p>
                        <div className="form-row">
                          <div className="form-group col-md-12">
                            <img src={ADMINICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />
                            <input
                              name="temperature"
                              id="temperature"
                              type="text"
                              placeholder="Temperature input for example 37.7"
                              className="form-control+matching-text-style"
                              required onChange={this.handleChangeTemp}
                              style={{ width: '300px' }}
                            />
                            <span style={{ display: this.state.showTemp, color: 'red' }}>Please supply a valid temperature reading.</span>
                            <br />
                            <span>Do you currently have any of the following symptoms?</span>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <div onChange={this.setCough.bind(this)}>
                              <span>Cough</span><br />
                              <input type="radio" value="Yes" name="cough" style={{ margin: '0px 3px 0px 0px' }} /><span style={{ margin: '0px 10px 0px 0px' }}>Yes</span>
                              <input type="radio" value="No" name="cough" style={{ margin: '0px 3px 0px 0px' }} />No
                            </div>
                          </div>

                          <div className="form-group col-md-6">
                            <div onChange={this.setFever.bind(this)}>
                              <span>Fever</span><br />
                              <input type="radio" value="Yes" name="fever" style={{ margin: '0px 3px 0px 0px' }} /><span style={{ margin: '0px 10px 0px 0px' }}>Yes</span>
                              <input type="radio" value="No" name="fever" style={{ margin: '0px 3px 0px 0px' }} />No
                            </div>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <div onChange={this.setBreath.bind(this)}>
                              <span>Shortness of breath</span><br />
                              <input type="radio" value="Yes" name="breath" style={{ margin: '0px 3px 0px 0px' }} /><span style={{ margin: '0px 10px 0px 0px' }}>Yes</span>
                              <input type="radio" value="No" name="breath" style={{ margin: '0px 3px 0px 0px' }} />No
                            </div>
                          </div>

                          <div className="form-group col-md-6">
                            <div onChange={this.setSmell.bind(this)}>
                              <span>Loss of sense of taste or smell</span><br />
                              <input type="radio" value="Yes" name="smell" style={{ margin: '0px 3px 0px 0px' }} /><span style={{ margin: '0px 10px 0px 0px' }}>Yes</span>
                              <input type="radio" value="No" name="smell" style={{ margin: '0px 3px 0px 0px' }} />No
                            </div>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <div onChange={this.setThroat.bind(this)}>
                              <span>Sore throat</span><br />
                              <input type="radio" value="Yes" name="throat" style={{ margin: '0px 3px 0px 0px' }} /><span style={{ margin: '0px 10px 0px 0px' }}>Yes</span>
                              <input type="radio" value="No" name="throat" style={{ margin: '0px 3px 0px 0px' }} />No
                            </div>
                          </div>

                          <div className="form-group col-md-6">
                            <div onChange={this.setPain.bind(this)}>
                              <span>Body aches/Muscle pain</span><br />
                              <input type="radio" value="Yes" name="pain" style={{ margin: '0px 3px 0px 0px' }} /><span style={{ margin: '0px 10px 0px 0px' }}>Yes</span>
                              <input type="radio" value="No" name="pain" style={{ margin: '0px 3px 0px 0px' }} />No
                            </div>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <div onChange={this.setChills.bind(this)}>
                              <span>Chills</span><br />
                              <input type="radio" value="Yes" name="chills" style={{ margin: '0px 3px 0px 0px' }} /><span style={{ margin: '0px 10px 0px 0px' }}>Yes</span>
                              <input type="radio" value="No" name="chills" style={{ margin: '0px 3px 0px 0px' }} />No
                            </div>
                          </div>

                          <div className="form-group col-md-6">
                            <div onChange={this.setNausea.bind(this)}>
                              <span>Nausea</span><br />
                              <input type="radio" value="Yes" name="nausea" style={{ margin: '0px 3px 0px 0px' }} /><span style={{ margin: '0px 10px 0px 0px' }}>Yes</span>
                              <input type="radio" value="No" name="nausea" style={{ margin: '0px 3px 0px 0px' }} />No
                            </div>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <div onChange={this.setVomit.bind(this)}>
                              <span>Vomiting</span><br />
                              <input type="radio" value="Yes" name="vomit" style={{ margin: '0px 3px 0px 0px' }} /><span style={{ margin: '0px 10px 0px 0px' }}>Yes</span>
                              <input type="radio" value="No" name="vomit" style={{ margin: '0px 3px 0px 0px' }} />No
                            </div>

                          </div>
                          <div className="form-group col-md-6">
                            <div onChange={this.setDiarrhea.bind(this)}>
                              <span>Diarrhoea</span><br />
                              <input type="radio" value="Yes" name="diarrhea" style={{ margin: '0px 3px 0px 0px' }} /><span style={{ margin: '0px 10px 0px 0px' }}>Yes</span>
                              <input type="radio" value="No" name="diarrhea" style={{ margin: '0px 3px 0px 0px' }} />No
                            </div>
                          </div>
                        </div>

                        <div className="text-center">
                          <input type="checkbox"
                            name="terms"
                            id="terms"
                            style={{ margin: '0px 5px 0px 0px' }}
                            onChange={(e) => { this.handleChange(e) }}
                            required
                          >
                          </input><span>I understand and agree to inform Pernod Ricard should I display and/or suffer from any of the above symptoms in terms of Section 1 and 2 whilst visiting the Pernod Ricard's premises</span>
                          <span style={{ display: this.state.showTerms, color: 'red' }}>Please accept the Pernod Ricard terms and conditions.</span>
                          <br />
                          <button style={{ width: '200px', margin: '20px 0px 90px 0px' }}
                            className="btn btn-secondary"
                            disabled={radiosDone < 10}>
                            Next
                          </button>
                        </div>
                      </div>
                    </FormWithConstraints>
                  </div>
                </Col>
                <Col></Col>
              </Row>
            </Container>

            <Modal
              show={this.state.showMessage}
              onHide={this.handleClose}
              className="text-center"
              backdrop="static"
              keyboard={false}>

              <Modal.Header>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  <img src={CovidNotice}></img>
                </p>
                <p>
                  <span style={{ color: "red", fontWeight: "bold" }}>We would like to request you to reschedule your visit after 10 days in light of our temporary visitor restriction policy.</span>
                </p>
                <p>
                  <Button variant="secondary" onClick={this.handleRemoveVisitor} >
                    Ok
                  </Button>
                </p>
              </Modal.Body>
              <Modal.Footer >

              </Modal.Footer>
            </Modal>

            <Footer></Footer>
          </div>
        </div>
      </div>
    )
  }
}

export default additionalQ;