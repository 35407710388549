import React, { Component } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ADMINICO from "../admin.png";
import Cookies from 'universal-cookie';
import Modal from "react-bootstrap/Modal";
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import axios from "axios";
import QrReader from 'react-qr-reader';
import Table from "react-bootstrap/Table";
import { Redirect } from 'react-router';
import Headers from './header';
import Footer from './footer';

const cookies = new Cookies();
const config = require('../config.json');

let scanned = false;

class visitorLeaving extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleBack = this.handleBack.bind(this);

        this.state = {
            redirect: false,
            visitorExist: true,
            showMessage: false,
            showQR: false,
            visitorLeaving: [],
            visitKey: "",
            qrScanResult: 'No result',
            id: ""
        };

        this.handleClose = this.handleClose.bind(this);

    }

    handleClose() {
        this.setState({ showMessage: false });
        this.setState({ showQR: false });
      }

    handleShowQR = () => {
        this.setState({ showQR: true });
    }

    handleError = err => {
        console.error(err)
      }

    handleBack() {
        this.setState({ directTo: "/dvHost" });
        this.setState({ redirect: true });
    }

    handleChange = e => {
        this.setState({ "id": e.target.value })
        this.form.validateFields(e.target);
    }

    handleLeaving = e => {
        e.preventDefault();
        if (this.state.id) {
            this.processVisitorLeavingWithID();
        }
    }

    processVisitorLeavingWithID = async () => {
        // console.log("fetchVisitor called");
        try {
            // Load the visitor details
            const params = {
                "id": this.state.id,
                "username": localStorage.getItem('email'),
                "usid": localStorage.getItem('usersession')
            };

            const visitorres = await axios.post(`${config.api.invokeUrl}/visitor/`, params, {
                "headers": {
                    "x-api-key": `${config.api.xapikey}`
                }
            });
            // console.log("LoadVisitor: " + res.data);
            this.setState({ visitor: visitorres.data }, function () {
            });

            console.log(this.state.visitor);

            if (this.state.visitor.length == 0) {

                // unregistered

            } else {
                const curTime = new Date().toLocaleString();
                //console.log(this.state.qrScanResult);

                const params = {
                    // "id": visitorres.data[0].id,
                    "visitkey": visitorres.data[0].latestvisit,
                    "dateleft": curTime
                };

                console.log(params);

                // return;

                try {
                    const res = await axios.patch(`${config.api.invokeUrl}/visitorleaving/`, params, {
                        "headers": {
                            "x-api-key": `${config.api.xapikey}`
                        }
                    });

                } catch (err) {
                    console.log(`An error has occurred while trying to update visitor leaving: ${err}`);
                }

                this.setState({ directTo: "/dvHost" });
                this.setState({ redirect: true });

            }

        } catch (err) {
            console.log(`An error has occurred: ${err}`);
        }
    }

    handleScan = data => {
        //console.log('handleScan');
        if (data) {
            if (scanned === false) {
                scanned = true;
                this.setState({ showQR: false });
                //console.log(data);
                this.setState({ qrScanResult: data })
                this.processVisitorLeavingWithQR();
            }
        }
    }

    processVisitorLeavingWithQR = async () => {

        try {

            const visitorres = await axios.get(`${config.api.invokeUrl}/visitorqr/` + this.state.qrScanResult, {
                "headers": {
                    "x-api-key": `${config.api.xapikey}`
                }
            });

            this.setState({ visitorLeaving: visitorres.data }, function () {
            });

            //console.log("after api call");

            console.log(visitorres.data[0]);
            //console.log(this.state.visitorLeaving);


            if (this.state.visitorLeaving.length == 0) {
                alert("Invalid QR code.");
                // this.setState({directTo: "/visitorwelcome"});
                // this.setState({redirect: true});
            } else {

                const curTime = new Date().toLocaleString();
                //console.log(this.state.qrScanResult);

                const params = {
                    "id": visitorres.data[0].id,
                    "latestvisit": visitorres.data[0].latestvisit,
                    "dateleft": curTime
                };

                console.log(params);

                return;

                try {
                    const res = await axios.post(`${config.api.invokeUrl}/visitorleaving/`, params, {
                        "headers": {
                            "x-api-key": `${config.api.xapikey}`
                        }
                    });

                } catch (err) {
                    console.log(`An error has occurred while trying to update visitor leaving: ${err}`);
                }

                this.setState({ directTo: "/dvHost" });
                this.setState({ redirect: true });
            }

        } catch (err) {
            console.log(`An error has occurred: ${err}`);
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to={{
                pathname: this.state.directTo,
                state: {
                    id: this.state.id
                }
            }} />;
        }
        return (
            <div className="page-container">
                <div className="content-wrap">
                    <Headers></Headers>
                    <p></p>

                    <FormWithConstraints
                        ref={form => this.form = form}
                        onSubmit={this.handleLeaving}
                        noValidate>

                        <div className="text-center">

                            <h3 className="mainTitleText" style={{ margin: '20px 0px 0px 0px' }}>Visitor Leaving</h3>

                            <br></br>

                            <Container>
                                <Row>
                                    <Col></Col>
                                    <Col xs={6}>
                                        <div className="form-group">
                                            <img src={ADMINICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />
                                            <input
                                                name="search"
                                                id="search"
                                                type="text"
                                                placeholder="Visitor ID/Passport Number"
                                                className="form-control+matching-text-style"
                                                required onChange={this.handleChange}
                                                style={{ width: '85%' }}
                                                autoComplete="off"
                                            />
                                            <FieldFeedbacks for="search">
                                                <FieldFeedback when="valueMissing">You cannot leave this field blank. Please capture ID/Passport number.</FieldFeedback>
                                            </FieldFeedbacks>
                                        </div>
                                    </Col>
                                    <Col></Col>
                                </Row>
                                <Row style={{ margin: '0px 0px 90px 0px' }}>
                                    <Col></Col>
                                    <Col xs={8}>
                                        <Button variant="danger" onClick={this.handleBack} style={{ margin: '5px 3px 5px 0px', width: '110px', fontSize: "12px" }}>Back</Button>{' '}
                                        <Button variant="info" onClick={this.handleShowQR} style={{ margin: '5px 3px 5px 0px', width: '110px', fontSize: "12px" }}>Scan out</Button>{' '}
                                        <Button variant="secondary" type="submit" style={{ margin: '5px 3px 5px 0px', width: '110px', fontSize: "12px" }}>Submit</Button>{' '}
                                    </Col>
                                    <Col></Col>
                                </Row>
                            </Container>
                        </div>
                    </FormWithConstraints>

                    <Modal
                        show={this.state.showQR}
                        onHide={this.handleClose}
                        className="text-center"
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Body>
                            <p className="subTitleText">
                                Please present your QR Code
                            </p>
                            {/* <div className="form-group"> */}
                            {/* <input
                name="idNumber"
                id="idNumber"
                type="text"
                placeholder="Enter ID Number"
                className="form-control+matching-text-style"
                style={{ width: "85%", margin: "0px 10px 0px 0px" }}
                value={this.state.inputValue}
                onChange={(evt) => this.updateInputValue(evt)}
              /> */}
                            {/* <Button variant="danger" onClick={this.visitorLeavingClick} style={{ width: "40px", height: "30px", margin: "-10px 0px 0px 0px" }}>
                <img
                  src={searchWhite}
                  onClick={this.visitorLeavingClick}
                  style={{
                    width: "20px",
                    padding: "0px 0px 0px 0px",
                    margin: "-10px 0px 0px 0px",
                    cursor: "pointer",
                  }} />
              </Button> */}
                            {/* </div> */}
                            <div className="form-group">
                                <span style={{ color: "red" }}>{this.state.searchState}</span>
                            </div>
                            <div>
                                <div className="tile is-4 is-parent  is-vertical" style={{ height: "350px" }}>
                                    <Table className="table-borderless" style={{ width: "100%", textAlign: "center" }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "33%", textAlign: "center" }}></td>
                                                <td style={{ width: "33%", textAlign: "center" }}>
                                                    <QrReader
                                                        delay={300}
                                                        onError={this.handleError}
                                                        onScan={this.handleScan}
                                                        style={{ width: '300px', height: '300px' }}
                                                        facingMode={'user'}
                                                    />
                                                </td>
                                                <td style={{ width: "33%", textAlign: "center" }}></td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>
                                                    <p>{this.state.qrScanResult}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Footer></Footer>
                </div>
            </div>
        );
    }

}

export default visitorLeaving;