import React, { Component } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import MAILPIC from "../mail-new.png";
import MOBILEPIC from "../mobile.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
  FormWithConstraints,
  FieldFeedbacks,
  Async,
  FieldFeedback,
} from "react-form-with-constraints";
import axios from "axios";
import { Redirect } from "react-router";
import { v4 as uuidv4 } from "uuid";
import './passwordRequest.css';
import spacer from "../spacer.png";
import Headers from "./header";

const config = require('../config.json');

class PasswordReq extends Component {
  constructor() {
    super();
    this.state = {
      name: "React",
      email: "",
      mobile: "",
      username: "",
      newUUID: "",
      admins: [],
      mode: "",
      id: "",
      country: "",
      buildingsregion: "",
      userrole: "",
      buildings: "",
      showEmailNotFound: "none",
      showMobileNotFound: "none",
      redirect: false,
      pathname: "/otpPassword"
    };
    this.onChangeValue = this.onChangeValue.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handlePrepMessage() {

    // console.log(this.state.email);
    // console.log(this.state.mobile);

    if (this.state.email !== undefined && this.state.email !== "") {
      //console.log("emailMode");
      //search admins array for matching email
      const findEmail = this.state.admins.find(element => element.email.toLowerCase() == this.state.email.toLowerCase());
      if (findEmail !== undefined) {
        //found match
        // console.log(findEmail.id);
        // console.log(findEmail);
        this.setState({ username: findEmail.id });
        //this.sendEmailMessage();
        //this.helpSendEmailMessage(findEmail.id,this.state.email);
        this.setState({ mode: "Email" });
        this.setState({ id: findEmail.id });
        this.setState({ username: findEmail.username });
        this.setState({ country: findEmail.country });
        this.setState({ buildingsregion: findEmail.buildingsregion });
        this.setState({ userrole: findEmail.userrole });
        this.setState({ buildings: findEmail.buildings });
        this.setState({ redirect: true });
      } else {
        //console.log("no email match");
        this.setState({ showEmailNotFound: "block" });
        this.setState({ showMobileNotFound: "none" });
      }
    }

    if (this.state.mobile !== undefined && this.state.mobile !== "") {
      //console.log("mobileMode");
      const findMobile = this.state.admins.find(element => element.mobile == this.state.mobile);
      if (findMobile !== undefined) {
        // console.log(findMobile.id);
        this.setState({ username: findMobile.id })
        //this.sendMessage(newGUID);
        this.setState({ mode: "Mobile" });
        this.setState({ id: findMobile.id });
        this.setState({ username: findMobile.username });
        this.setState({ country: findMobile.country });
        this.setState({ buildingsregion: findMobile.buildingsregion });
        this.setState({ userrole: findMobile.userrole });
        this.setState({ buildings: findMobile.buildings });
        this.setState({ redirect: true });
      } else {
        //console.log("no mobile match");
        this.setState({ showEmailNotFound: "none" });
        this.setState({ showMobileNotFound: "block" });
      }
    }
  }

  handleCancel() {
    this.setState({ redirect: true });
    this.setState({ pathname: "/" });
  }

  fetchAdmins = async () => {
    try {
      const param = {
        // id: res.data.mail.toLowerCase(),
        id: localStorage.getItem('email'),
        usid: localStorage.getItem('usersession')
      };

      const res = await axios.get(`${config.api.invokeUrl}/adminuserscountry`, param, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });
      // console.log(res.data);
      this.setState({ admins: res.data });
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  };

  onEmailChange = (event) => {
    this.setState({ email: event.target.value });
    this.setState({ mobile: "" });
  }

  onMobileChange = (event) => {
    this.setState({ mobile: event.target.value });
    this.setState({ email: "" });
  }

  onChangeValue(event) {
    // console.log(event.target.value);
  }

  componentDidMount = () => {
    this.fetchAdmins();
  }

  adminSubmit = (e) => {
    e.preventDefault();

    this.form.validateFields();

    if (!this.form.isValid()) {
      // console.log("form is invalid: do not submit");
    } else {
      console.log("form is valid: submit");
      this.handlePrepMessage();
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.pathname,
        state: {
          id: this.state.id,
          mobile: this.state.mobile,
          email: this.state.email,
          mode: this.state.mode,
          country: this.state.country,
          buildingsregion: this.state.buildingsregion,
          userrole: this.state.userrole,
          buildings: this.state.buildings
        }
      }} />;
    }
    return (
      <div className="page-container">
        <div className="content-wrap">
          <Headers></Headers>
          <p></p>
          <div className="text-center">
            <span className="dvHeaderText">Forgot Password</span>
            <p>
              <span>Capture your registered email address or mobile number.</span>
            </p>
          </div>
          <FormWithConstraints
            ref={(form) => (this.form = form)}
            onSubmit={this.adminSubmit}
            noValidate
          >
            <Container>
              <Row>
                <Col></Col>
                <Col xs={6}>
                  <div className="form-group">
                    <img
                      src={MAILPIC}
                      style={{
                        width: "20px",
                        padding: "0px 0px 10px 0px",
                        margin: "0px 5px 0px 0px",
                      }}
                    />

                    <input
                      name="email"
                      id="email"
                      type="email"
                      placeholder="Email Address"
                      className="form-control+matching-text-style"
                      onChange={this.onEmailChange}
                      style={{ width: "85%" }}
                      value={this.state.email}
                    />
                    <FieldFeedbacks for="email">
                      <FieldFeedback when="*" />
                    </FieldFeedbacks>
                  </div>
                  <div style={{ display: this.state.showEmailNotFound, color: 'red' }}><p>The email could not be found</p></div>
                  <p className="text-center">Or</p>

                  <div className="form-group">
                    <img
                      src={MOBILEPIC}
                      style={{
                        width: "20px",
                        padding: "0px 0px 10px 0px",
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                    <input
                      name="mobile"
                      id="mobile"
                      type="text"
                      placeholder="Mobile number format +27821234567"
                      className="form-control+matching-text-style"
                      onChange={this.onMobileChange}
                      style={{ width: "85%" }}
                      value={this.state.mobile}
                    />
                    <FieldFeedbacks for="mobile">
                      <FieldFeedback when="*" />
                    </FieldFeedbacks>
                  </div>
                  <div style={{ display: this.state.showMobileNotFound, color: 'red' }}><p>The mobile number could not be found.</p></div>
                  <p />
                  <div className="text-center" style={{ margin: "0px 0px 80px 0px" }}>
                    <button
                      className="btn btn-secondary"
                      style={{ width: "110px", margin: "0px 3px 5px 0px" }}>
                      Send OTP
                    </button>
                    <button
                      href="/"
                      className="btn btn-danger"
                      style={{ width: "110px", margin: "0px 0px 5px 0px" }}
                      onClick={this.handleCancel}>
                      Cancel
                    </button>
                  </div>
                </Col>
                <Col></Col>
              </Row>
            </Container>
          </FormWithConstraints>
          <div className="main-footer">
            <div className="container">
              <img src={spacer}></img>
            </div>
            <div className="page-containerRight"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordReq;
