import React, { Component } from 'react';
import Webcam from 'react-webcam';

class test extends Component {    

    componentDidMount = () => {       
        const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
        // console.log(supportedConstraints);
        alert("facingMode: " + supportedConstraints.facingMode);

      }
    
    render() { 
        const videoConstraints = {
            facingMode: "user"
          };

          
       
          return <Webcam audio={false} videoConstraints={videoConstraints} />;
    }



}
export default test;