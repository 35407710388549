import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OTPClient from 'otp-client';
import OtpInput from 'react-otp-input';
import Headers from './header';
import Footers from './footer';
import axios from 'axios';
import './otpFields.css';
import { Redirect } from 'react-router';
import Footer from './footer';


const config = require('../config.json');

class otpRegister extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleCancel = this.handleCancel.bind(this);

    this.state = {
      otp: '',
      redirect: false,
      id: this.props.location.state.id,
      email: this.props.location.state.email,
      firstname: this.props.location.state.firstname,
      lastname: this.props.location.state.lastname,
      company: this.props.location.state.company,
      mobile: this.props.location.state.mobile,
      // Mode: this.props.location.state.mode,
      Mode: 'Email',
      numInputs: 6,
      separator: '',
      isDisabled: false,
      hasErrored: false,
      isInputNum: false,
      minLength: 0,
      maxLength: 40,
      newOTP: '',
      pathname: "/register",
      showOTPError: "none"
    };

  }

  handleOtpChange = otp => {
    this.setState({ otp });
  };

  handleCancel() {
    this.setState({ redirect: true });
    this.setState({ pathname: "/registerSave" });
  }

  handleChange = e => {
    let currVal = e.target.value;

    if (e.target.name === 'numInputs') {
      const { minLength, maxLength } = this.state;

      if (currVal < minLength || currVal > maxLength) {
        currVal = 4;

        console.error(
          `Please enter a value between ${minLength} and ${maxLength}`
        );
      }
    }

    this.setState({ [e.target.name]: currVal });
  };

  clearOtp = () => {
    this.setState({ otp: '' });
  };

  handleCheck = e => {
    const { name } = e.target;
    this.setState(prevState => ({ [name]: !prevState[name] }));
  };

  handleSubmit = e => {
    e.preventDefault();
    //alert(this.state.otp);
    //alert(this.state.newOTP);
    if (this.state.otp == this.state.newOTP) {
      this.setState({ redirect: true });
    } else {
      //alert("not EQ");
      this.setState({ showOTPError: "block" });
      this.setState({ hasErrored: true });
    }
  };

  componentDidMount = () => {
    // console.log(this.state.Mode);
    if ("Email" == this.state.Mode) {
      this.sendEmailMessage();
    }
    if ("Mobile" == this.state.Mode) {
      this.sendMessage();
    }
  }

  resendOTP = e => {
    e.preventDefault();
    this.sendEmailMessage();

    // e.preventDefault();
    // if ("Email" == this.state.Mode) {
    //   this.sendEmailMessage();
    // }
    // if ("Mobile" == this.state.Mode) {
    //   this.sendMessage();
    // }
  };

  sendMessage = () => {
    const secret = 'TPQDAHVBZ5NBO5LFEQKC7V7UPATSSMFY';
    const otp = new OTPClient(secret);
    const token = otp.getToken();

    this.setState({ newOTP: token }, function () {
      console.log(this.state.newOTP);
      // //    console.log(this.state.mobile);
    });


    var data = JSON.stringify({
      "message": "Pernod Ricard Digital Visitor " + token,
      "token": `${config.api.SMStoken}`,
      "destination": this.state.mobile
    });

    var config = {
      method: 'post',
      url: `${config.api.SMSUrl}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  sendEmailMessage = async () => {
    const secret = 'TPQDAHVBZ5NBO5LFEQKC7V7UPATSSMFY';
    const otp = new OTPClient(secret);
    const token = otp.getToken();
    const emailBody = "<span>Dear Visitor</span><p><span> Complete your registration to Digital Visitor with the below OTP number: </span></p>" + token + "<p><span></span>Regards,<br /><span>Digital Visitors Team</span></p>"

    this.setState({ newOTP: token }, function () {
      //    console.log(this.state.newOTP);
    });

      const params = {
        "toaddress": [this.state.email],
        "subject": "Pernod Ricard Digital Visitors OTP",
        "body": emailBody
      };
      //console.log(params);

      
  
      try {
        const res = await axios.post(`${config.api.invokeUrl}/email/`, params, {
          "headers": {
            "x-api-key": `${config.api.xapikey}`
          }
        });
  
        if (res.status == "200") {       
          // this.setState({ redirect: true });

          console.log("Email sent!");

        } else {
          console.log(`An error has occurred trying to send email`);
        }
  
      } catch (err) {
        console.log(`An error has occurred trying to send email: ${err}`);
      }
  }

  render() {
    const {
      otp,
      numInputs,
      separator,
      isDisabled,
      hasErrored,
      isInputNum,
      minLength,
      maxLength,
      newOTP,
    } = this.state;

    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.pathname,
        state: {
          id: this.state.id,
          email: this.state.email,
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          company: this.state.company,
          mobile: this.state.mobile
        }
      }} />;
    }
    return (
      <div>
        <div className="text-center" style={{ margin: '30px 0px 0px 0px' }}>
          <p>
            {/* <span style={{ fontSize: '20pt' }}>OTP has been sent to {this.state.email} </span> */}
            <span style={{ fontSize: '15px' }}>OTP has been sent to email address {this.state.email.substring(0, 15)}*****{(this.state.email.split('@')[1]).split('.')[1]}</span>
          </p>
          <p>
            <span style={{ display: this.state.showOTPError, color: 'red' }}>Please click resend OTP and try again or click cancel</span>
          </p>
          <br />
          <p /><h3>Capture OTP</h3>
          <form onSubmit={this.handleSubmit}>
            <div className="containerOTP">
              <div className="view">
                <div className="card">
                  <OtpInput
                    inputStyle={{
                      width: "3rem",
                      height: "3rem",
                      margin: "5px",
                      fontSize: "1rem",
                      borderRadius: 4,
                      border: "2px solid rgba(0,0,0,0.3)",
                    }}
                    numInputs={numInputs}
                    isDisabled={isDisabled}
                    hasErrored={hasErrored}
                    errorStyle="error"
                    onChange={this.handleOtpChange}
                    separator={<span>{separator}</span>}
                    isInputNum={isInputNum}
                    shouldAutoFocus
                    value={otp}
                  />
                  <div >
                    <button
                      style={{ margin: '10px 0px 0px 0px' }}
                      className="btn btn-secondary"
                      disabled={otp.length < numInputs}>
                      Submit
                    </button>
                    <button
                      style={{ margin: '10px 0px 0px 10px' }}
                      className="btn btn-danger"
                      onClick={this.handleCancel}>
                      Cancel
                    </button>
                    <br />
                    <button style={{ margin: '10px 0px 70px 0px', textDecoration: 'underline', fontWeight: 'bold', color: '#4A4D4E' }} className="btn" onClick={this.resendOTP} >Resend OTP</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <br />

        <Footer></Footer>
      </div>
    );
  }
}

export default otpRegister;